import { Tag, Tooltip } from 'antd'
import { SelectBlockProps } from 'components/survey-creator/question-blocks/sub-components/select-block-common-component/types'
import React from 'react'

const SelectTagsCommonComponent = (props: SelectBlockProps): any[] => {
  const output = []
  if (props.randomize) {
    output.push(
      <Tooltip overlay={'Randomizes answers'} key={'random'}>
        <Tag>Randomize</Tag>
      </Tooltip>
    )
  }
  if (props.other) {
    output.push(
      <Tooltip overlay={'Includes "Other" as an answer'} key={'other'}>
        <Tag>Other</Tag>
      </Tooltip>
    )
  }
  if (props.noneOfTheAbove) {
    output.push(
      <Tooltip
        overlay={'Includes "None of the above" as an answer'}
        key={'answer'}
      >
        <Tag>None of the above</Tag>
      </Tooltip>
    )
  }
  return output
}

export default SelectTagsCommonComponent
