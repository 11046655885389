import React from 'react'
import BasePropertyBlock from '../base/properties'
import SelectPropertiesComponent from '../sub-components/select-properties-common-component'

const SingleSelectProperties = props => (
  <BasePropertyBlock>
    <SelectPropertiesComponent {...props} />
  </BasePropertyBlock>
)

export default React.memo(SingleSelectProperties)
