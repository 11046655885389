import { FolderOutlined } from '@ant-design/icons'
import { Menu } from 'antd'
import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { MainRouteConstants } from 'routes/main'
import * as RouteConstants from 'routes/route-constants'
import './style.scss'

// const { SubMenu } = Menu

const MenuComponent = () => {
  const location = useLocation()
  const selectedKeys = () => {
    const routes = Object.values(RouteConstants)
      .map(i => Object.values(i))
      .flat()
      .map(i => i.replace('/:id', ''))

    return routes.filter(i => location.pathname.startsWith(i))
  }

  // temporary hide
  // const history = useHistory()
  // const workspaceId = useCurrentWorkspaceId()
  //
  // const handleCreateSurvey = async () => {
  //   const data = await postSurveyApi(workspaceId, {
  //     name: `Draft ${format(new Date(), 'yyyy-MM-dd HH:mm')}`,
  //     type: TemplateType.CUSTOM
  //   })
  //   history.push(`/creator/audience/${data.id}`)
  // }

  return (
    <Menu
      selectedKeys={selectedKeys()}
      mode="horizontal"
      className={'header-menu'}
    >
      <Menu.Item
        key={MainRouteConstants.WORKSPACE.replace('/:id', '')}
        icon={<FolderOutlined />}
      >
        <Link to={MainRouteConstants.MAIN}>Workspaces</Link>
      </Menu.Item>

      {/*<SubMenu*/}
      {/*  key={TemplateRouteConstants.INTERSTITIAL}*/}
      {/*  icon={*/}
      {/*    <Link*/}
      {/*      to={{*/}
      {/*        pathname: TemplateRouteConstants.INTERSTITIAL,*/}
      {/*        search: location.search*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <SettingOutlined />*/}
      {/*    </Link>*/}
      {/*  }*/}
      {/*  title={*/}
      {/*    <Link*/}
      {/*      to={{*/}
      {/*        pathname: TemplateRouteConstants.INTERSTITIAL,*/}
      {/*        search: location.search*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <span>*/}
      {/*        Solutions*/}
      {/*        <CaretDownOutlined*/}
      {/*          style={{ marginLeft: Spacing.TINY, fontSize: 12 }}*/}
      {/*        />*/}
      {/*      </span>*/}
      {/*    </Link>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <Menu.Item key={TemplateRouteConstants.MARKET_RESEARCH_LIST}>*/}
      {/*    <Link*/}
      {/*      to={{*/}
      {/*        pathname: TemplateRouteConstants.MARKET_RESEARCH_LIST,*/}
      {/*        search: location.search*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      Automated Market Research*/}
      {/*    </Link>*/}
      {/*  </Menu.Item>*/}
      {/*  <Menu.Item*/}
      {/*    key={TemplateRouteConstants.CUSTOM_SURVEY_LIST}*/}
      {/*    icon={<PlusOutlined />}*/}
      {/*    onClick={handleCreateSurvey}*/}
      {/*  >*/}
      {/*    Create a custom survey*/}
      {/*  </Menu.Item>*/}
      {/*</SubMenu>*/}
    </Menu>
  )
}
export default MenuComponent
