import { Select, Space } from 'antd'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import BaseLogicComponent from '../base/logic'
import { useHandleFieldUpdate } from '../base/logic/hooks'
import { BaseLogicSubComponentProps } from '../base/types'
import { RankingProps } from './types'

const RankingLogicSubcomponent = ({
  logicId,
  action,
  actionGroupId
}: BaseLogicSubComponentProps) => {
  const activeBlock = useSelector(CreatorSelectors.activeBlock) as RankingProps

  const handleFieldUpdate = useHandleFieldUpdate(logicId, actionGroupId, action)

  const handleIndexChange = index => handleFieldUpdate({ index })

  const handleValueChange = value => handleFieldUpdate({ value })

  return (
    <Space>
      <Select
        style={{ minWidth: 160, width: '100%' }}
        value={action?.condition?.field?.value}
        onChange={handleValueChange}
      >
        {activeBlock.choices?.map((i, index) => (
          <Select.Option value={i} key={i}>
            {i}
          </Select.Option>
        ))}
      </Select>
      at
      <Select
        style={{ width: 70 }}
        value={action.condition?.field?.index}
        onChange={handleIndexChange}
      >
        {activeBlock.choices?.map((i, index) => (
          <Select.Option value={index} key={index}>
            {index + 1}
          </Select.Option>
        ))}
      </Select>
    </Space>
  )
}

const RankingLogic = () => (
  <BaseLogicComponent component={RankingLogicSubcomponent} />
)

export default RankingLogic
