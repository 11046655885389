import { Button, Checkbox, Col, Form, Input, Row, Typography } from 'antd'
import AuthBase from 'pages/auth/auth-base'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { firebaseLogin } from 'services/integrations/firebase'
import { Spacing } from 'services/styles'
import UAskLogo from 'static/logo.png'

const { Title, Text } = Typography

const LoginPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)

  const onFinish = async ({ username, password }: any) => {
    setIsLoading(true)
    setHasError(false)
    try {
      await firebaseLogin(username, password)
    } catch (e) {
      setHasError(true)
      setIsLoading(false)
      console.log(e.message)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <AuthBase>
      <Row style={{ width: '100%', maxWidth: 600 }}>
        <Col span={18} offset={3}>
          <img
            src={UAskLogo}
            style={{ height: 60, marginBottom: Spacing.MEDIUM }}
            alt={'uask-logo'}
          />
          <Title level={2} style={{ marginBottom: 0 }}>
            Sign in to UAsk.io
          </Title>
          <Title
            level={5}
            style={{
              marginTop: Spacing.TINY / 2,
              marginBottom: Spacing.SMALL * 1.5,
              fontWeight: 400
            }}
          >
            or <Link to={'/register'}>register an account</Link>
          </Title>
          <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { message: 'Please input your username!', required: true }
              ]}
            >
              <Input size={'large'} disabled={isLoading} />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { message: 'Please input your password!', required: true }
              ]}
            >
              <Input.Password size={'large'} disabled={isLoading} />
            </Form.Item>
            {hasError && (
              <div
                style={{
                  marginBottom: Spacing.SMALL,
                  marginTop: Spacing.SMALL
                }}
              >
                <Text type="danger">
                  The username/password combination is invalid.
                </Text>
              </div>
            )}

            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Link to={'/forgot-password'} style={{ float: 'right' }}>
                Forgot password
              </Link>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                size={'large'}
                loading={isLoading}
              >
                Login
              </Button>
            </Form.Item>
          </Form>
          {/*<Divider>OR</Divider>*/}
          {/*<Button*/}
          {/*  disabled*/}
          {/*  type="ghost"*/}
          {/*  block*/}
          {/*  size={'large'}*/}
          {/*  onClick={googleSignInWithPopup}*/}
          {/*  icon={*/}
          {/*    <img*/}
          {/*      src="https://img.icons8.com/color/25/000000/google-logo.png"*/}
          {/*      style={{ marginRight: Spacing.SMALL }}*/}
          {/*      alt="google-icon"*/}
          {/*    />*/}
          {/*  }*/}
          {/*>*/}
          {/*  Sign in with Google*/}
          {/*</Button>*/}
        </Col>
      </Row>
    </AuthBase>
  )
}

export default LoginPage
