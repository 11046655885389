import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Select, Space, Tooltip } from 'antd'
import { CreatorSelectors } from 'data/creator'
import React from 'react'
import { useSelector } from 'react-redux'
import { Spacing } from 'services/styles'
import { BlocksDefinition } from '../../'
import {
  ActionOperation,
  LogicAction,
  LogicActionGroup,
  OperationToCanonical
} from '../types'
import { useHandleLogicChange } from './hooks'

const QuestionHeader = ({ text }) => <div style={{ width: 65 }}>{text}</div>

interface ActionComponentProps {
  logicId: string
  action: LogicAction
  component: any
  actionGroup: LogicActionGroup
  isFirst: boolean
}

const ActionComponent = ({
  logicId,
  action,
  component: Component,
  actionGroup,
  isFirst
}: ActionComponentProps) => {
  const activeBlock = useSelector(CreatorSelectors.activeBlock)

  const actionGroupId = actionGroup.actionGroupId
  const operations =
    BlocksDefinition[activeBlock.type].blockDefinition.operations

  const {
    handleActionEqualityChange,
    handleActionOperationChange,
    handleAddLogicToActionGroup,
    handleDeleteLogicFromAction
  } = useHandleLogicChange()

  const _handleOperationChange = e =>
    handleActionOperationChange(logicId, e, actionGroup.actionGroupId)

  const _handleAddLogicToAction = () =>
    handleAddLogicToActionGroup(logicId, actionGroupId)

  const _handleDeleteLogicFromAction = () =>
    handleDeleteLogicFromAction(logicId, actionGroupId, action.actionId)

  return (
    <div key={action.actionId}>
      <div style={{ marginTop: !isFirst && Spacing.SMALL }}>
        <Space>
          {isFirst ? (
            <QuestionHeader text={'If answer'} />
          ) : (
            <div>
              <Select
                style={{ width: '100px' }}
                value={actionGroup.actionOperation}
                onChange={_handleOperationChange}
              >
                {Object.keys(ActionOperation).map(i => (
                  <Select.Option value={i} key={i}>
                    {i}
                  </Select.Option>
                ))}
              </Select>
            </div>
          )}
          <Select
            style={{ minWidth: 160, width: '100%' }}
            value={action?.condition?.operation}
            onChange={e =>
              handleActionEqualityChange(
                logicId,
                e,
                actionGroupId,
                action.actionId
              )
            }
          >
            {operations?.map((i, index) => (
              <Select.Option value={i} key={i} style={{ width: '100%' }}>
                {OperationToCanonical[i]}
              </Select.Option>
            ))}
          </Select>

          <Component
            action={action}
            actionGroupId={actionGroupId}
            logicId={logicId}
          />

          <Tooltip overlay="Delete logic">
            <Button
              icon={<MinusOutlined />}
              onClick={_handleDeleteLogicFromAction}
            />
          </Tooltip>
          <Tooltip overlay="Add logic">
            <Button icon={<PlusOutlined />} onClick={_handleAddLogicToAction} />
          </Tooltip>
        </Space>
      </div>
    </div>
  )
}
export default ActionComponent
