import TextInputClickable from 'components/survey-creator/text-input-clickable'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useParamSelector } from 'services/hooks'
import { Spacing } from 'services/styles'
import BaseBlock from '../base/block'
import TextChoices from '../sub-components/text-choices'
import { SliderBlockProps } from './types'

const SliderSubComponent = (props: SliderBlockProps) => {
  const dispatch = useDispatch()
  const blockState = useParamSelector(
    CreatorSelectors.getBlockById,
    props.blockId
  )

  const onEnterNewLine = props => {
    const newReduxState = {
      ...blockState,
      choices: [...blockState.choices, props.target.value]
    }
    dispatch(CreatorActions.putBlock(newReduxState))
  }
  const { min, max } = props.range

  const renderPrefix = index => {
    const range = max - min
    let prefixNumber
    if (index === 0) {
      prefixNumber = min
    } else if (index === blockState.choices.length - 1) {
      prefixNumber = max
    } else {
      prefixNumber = (range / (blockState.choices.length - 1)) * index
    }
    return Math.floor(prefixNumber).toString()
  }

  return (
    <>
      {blockState.choices.map((choice, index) => (
        <div style={{ marginLeft: 2 }} key={`${index}`}>
          <TextChoices
            state={blockState}
            choice={choice}
            index={index}
            placeholder="Enter a choice"
            prefixText={renderPrefix(index)}
            disabledDelete={blockState.choices.length === 2}
            disabledRearrange={blockState.choices.length === 2}
          />
        </div>
      ))}
      <div style={{ marginLeft: Spacing.MEDIUM * 1.8 }}>
        <TextInputClickable
          value={''}
          onChange={onEnterNewLine}
          placeholder="Type an answer"
        />
      </div>
    </>
  )
}

const TextBlock = props => (
  <BaseBlock {...props}>
    <SliderSubComponent {...props} />
  </BaseBlock>
)

export default TextBlock
