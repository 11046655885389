import { Divider, Select } from 'antd'
import FlexContainer from 'components/flex-container'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import startCase from 'lodash/startCase'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BasePropertyBlock from '../base/properties'
import { TextProps, TextType } from './types'

const TextBlockPropertiesComponent = () => {
  const activeBlock = useSelector(CreatorSelectors.activeBlock) as TextProps
  const dispatch = useDispatch()

  const onStarChange = textType => {
    dispatch(
      CreatorActions.putBlock({
        ...activeBlock,
        textType
      })
    )
  }
  return (
    <>
      <Divider />
      <p>Text type</p>
      <Select
        style={{ width: '100%' }}
        value={activeBlock.textType}
        onChange={onStarChange}
      >
        {Object.values(TextType).map(i => (
          <Select.Option value={i} label={i} key={i}>
            <FlexContainer justifyContent={'flex-start'}>
              {startCase(i.toLowerCase())}
            </FlexContainer>
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

const TextBlockProperties = props => (
  <BasePropertyBlock>
    <TextBlockPropertiesComponent {...props} />
  </BasePropertyBlock>
)

export default React.memo(TextBlockProperties)
