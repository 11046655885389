import { Input } from 'antd'
import FlexContainer from 'components/flex-container'
import AnswerPrompt from 'components/survey-creator/question-blocks/answer-prompt'
import {
  TextAnswerProps,
  TextType
} from 'components/survey-creator/question-blocks/text/types'
import React from 'react'
import BaseAnswer from '../base/answer'

const { TextArea } = Input

const TextAnswerComponent = (props: TextAnswerProps) => {
  const { onAnswerUpdate } = props

  const onTextChange = e => onAnswerUpdate(e.target.value)

  return (
    <FlexContainer
      justifyContent={'center'}
      flexDirection={'column'}
      style={{ width: '100%' }}
    >
      <AnswerPrompt text={'ENTER ANSWER BELOW'} />
      {props.textType === TextType.SINGLE_LINE ? (
        <Input onChange={onTextChange} placeholder="Type your answer" />
      ) : (
        <TextArea
          placeholder="Type your answer"
          onChange={onTextChange}
          size={'large'}
        />
      )}
    </FlexContainer>
  )
}

const TextAnswer = props => (
  <BaseAnswer {...props} component={TextAnswerComponent} />
)

export default TextAnswer
