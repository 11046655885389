import { VscQuote } from 'react-icons/all'
import {
  BaseBlockProps,
  BaseBlockWithAnswers,
  BlockDefinition,
  BlockType,
  InitialBaseBlock,
  QuestionColor
} from '../base/types'

export interface StatementBlockProps extends BaseBlockProps {}

const StatementInitialProps: StatementBlockProps = {
  ...InitialBaseBlock,
  type: BlockType.STATEMENT
}

export type StatementAnswerProps = StatementBlockProps & BaseBlockWithAnswers

const StatementBlockDefinition: BlockDefinition = {
  title: 'Statement',
  description: 'Short text explanation',
  color: QuestionColor[BlockType.STATEMENT],
  icon: VscQuote,
  type: BlockType.STATEMENT,
  initialProps: StatementInitialProps
}

export { StatementBlockDefinition }
