import { HiChartBar } from 'react-icons/all'
import {
  BaseBlockProps,
  BaseBlockWithAnswers,
  BlockDefinition,
  BlockType,
  InitialBaseBlock,
  Operation,
  QuestionColor
} from '../base/types'

export interface RankingProps extends BaseBlockProps {
  choices: string[]
  randomize: boolean
}

export type RankingAnswerProps = RankingProps & BaseBlockWithAnswers

const RankingInitialProps: RankingProps = {
  ...InitialBaseBlock,
  type: BlockType.RANKING,
  choices: [],
  randomize: false
}

const RankingDefinition: BlockDefinition = {
  title: 'Ranking',
  description: 'Order item by preference',
  color: QuestionColor[BlockType.RANKING],
  icon: HiChartBar,
  type: BlockType.RANKING,
  initialProps: RankingInitialProps,
  operations: [Operation.EQUAL, Operation.NOT_EQUAL]
}

export { RankingDefinition }
