const Color = {
  DARK: '#000000',
  GREY_3: '#585858',
  GREY_2: '#a1a1a1',
  GREY_1: '#d9d9d9',
  GREY: '#eeeeee',
  WHITE: '#ffffff',
  DIRTY_WHITE: 'rgb(250,250,250)',
  BORDER_COLOR: 'rgb(238, 238, 238)',
  CLICKABLE: '#488cbd',
  DANGER: '#E73D57',
  PRIMARY: '#FD676B',
  STALE_GRAY: '#6A778E',
  DARK_BLUE: '#1E2D4F'
}

export default Color
