import { WorkspaceReducer as workspace, WorkspaceState } from 'data/workspace'
import { combineReducers } from 'redux'
import { CreatorReducer as creator, CreatorState } from './creator'
import { OverlayReducer as overlay, OverlayState } from './overlay'
import { UserReducer as user, UserState } from './user'

export const state = combineReducers<AppReducerState>({
  user,
  creator,
  overlay,
  workspace
})

export interface AppReducerState {
  readonly user: UserState
  readonly creator: CreatorState
  readonly overlay: OverlayState
  readonly workspace: WorkspaceState
}

export default state
