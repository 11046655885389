import { UserSelectors } from 'data/user'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { AuthRouteDefinition } from 'routes/auth'
import { whiteListedRoutes } from 'routes/root/component'

export const useHandleAuthRouting = (isDashboard = true) => {
  const isFirebaseReady = useSelector(UserSelectors.isFirebaseReady)
  const user = useSelector(UserSelectors.user)
  const isFetchingUser = useSelector(UserSelectors.isFetchingUser)
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    if (!isDashboard) return

    if (whiteListedRoutes.some(i => location.pathname.startsWith(i))) return
    if (isFirebaseReady && !isFetchingUser) {
      const isCurrentRouteAuth = AuthRouteDefinition.some(i =>
        location.pathname.startsWith(i.path)
      )
      if (!user) {
        if (!isCurrentRouteAuth) {
          history.push(AuthRouteDefinition[0].path)
        }
      } else {
        if (!user.isValidated) {
          if (
            location.pathname.startsWith('/activate') ||
            location.pathname.startsWith('/confirm')
          ) {
          } else {
            history.push('/activate')
          }
        } else {
          if (isCurrentRouteAuth) history.push('/')
        }
      }
    }
  }, [isDashboard, user, history, location, isFirebaseReady, isFetchingUser])

  return [user, isFirebaseReady, isFetchingUser]
}
