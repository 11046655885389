import { ConfigProvider, Spin } from 'antd'
import OverlayComponent from 'components/overlay-component'
import config from 'config'
import { persistor, store } from 'data'
import { getAnalytics, logEvent, setCurrentScreen } from 'firebase/analytics'
import 'hammerjs'
import { createBrowserHistory } from 'history'
import React from 'react'
import { hotjar } from 'react-hotjar'
import { QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { IntercomProvider } from 'react-use-intercom'
import { PersistGate } from 'redux-persist/integration/react'
import { RootRouteComponent } from 'routes/root'
import 'services/api/common/base-interceptor'
import queryClient from 'services/api/common/react-query'
import { initSentry } from 'services/integrations'
import './App.less'
import './App.scss'
import './wdyr'

export const browserHistory = createBrowserHistory()

const analytics = getAnalytics()

//  @ts-ignore
initSentry(browserHistory)

if (config.hotjarId) {
  hotjar.initialize(config.hotjarId, 6)
}

browserHistory.listen(location => {
  const pagePath = location.pathname + location.search
  setCurrentScreen(analytics, pagePath)
  logEvent(analytics, 'page_view', { page_path: pagePath })
})

const App = () => (
  <QueryClientProvider client={queryClient}>
    <IntercomProvider appId={config.intercomAppId} autoBoot>
      <Router history={browserHistory}>
        <Provider store={store}>
          <PersistGate loading={<Spin size="large" />} persistor={persistor}>
            <ConfigProvider
              //  @ts-ignore
              getPopupContainer={_ => document.getElementById('popup')}
            >
              <div
                className="sl"
                id="popup"
                style={{ position: 'absolute', zIndex: 999999 }}
              />
              <OverlayComponent />
              <RootRouteComponent />
            </ConfigProvider>
          </PersistGate>
        </Provider>
      </Router>
    </IntercomProvider>
  </QueryClientProvider>
)

export default App
