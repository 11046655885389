const enterToTab = e => {
  try {
    if (e.keyCode === 13) {
      const inputs = Array.from(
        e.currentTarget?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.querySelectorAll(
          'input'
        ) ?? []
        //  @ts-ignore
      ).filter(e => !e?.disabled)
      const index = inputs.indexOf(e.currentTarget)
      //  @ts-ignore
      inputs[index + 1]?.focus()
      e.preventDefault()
    }
  } catch (e) {}
}

export default enterToTab
