import { ImRadioChecked2 } from 'react-icons/im'
import {
  BlockDefinition,
  BlockType,
  Operation,
  QuestionColor
} from '../base/types'
import {
  InitialSelectBlockProps,
  SelectBlockProps
} from '../sub-components/select-block-common-component/types'

const SingleSelectInitialProps: SelectBlockProps = {
  ...InitialSelectBlockProps,
  type: BlockType.SINGLE_SELECT
}

const SingleSelectBlockDefinition: BlockDefinition = {
  title: 'Single Selection',
  description: 'For single answers',
  color: QuestionColor[BlockType.SINGLE_SELECT],
  icon: ImRadioChecked2,
  type: BlockType.SINGLE_SELECT,
  initialProps: SingleSelectInitialProps,
  operations: [Operation.EQUAL, Operation.NOT_EQUAL]
}

export { SingleSelectBlockDefinition }
