import handleActionsImmer from 'services/integrations/immer'
import OverlayConstants from './constants'
import { OverlayState } from './types'

const overlay = handleActionsImmer<OverlayState>(
  {
    [OverlayConstants.SHOW_MODAL]: (state, { modal }) => {
      state.modals.push(modal)
    },
    [OverlayConstants.HIDE_MODAL]: (state, { id }) => {
      state.modals = state.modals.map(modal =>
        modal.id === id ? { ...modal, visible: false } : modal
      )
    },
    [OverlayConstants.HIDE_MODAL_COMPLETE]: (state, { id }) => {
      state.modals = state.modals.filter(i => i.id !== id)
    }
  },
  { modals: [] }
)

export default overlay
