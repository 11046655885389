import { MainPage } from 'pages/main'
import MainRouteConstants from 'routes/main/constants'
import { RouteDefinition } from 'routes/types'

const MainRoutesDefinition: RouteDefinition[] = [
  {
    path: MainRouteConstants.MAIN,
    component: MainPage,
    exact: true
  },
  {
    path: MainRouteConstants.WORKSPACE,
    component: MainPage,
    exact: true
  }
]
export default MainRoutesDefinition
