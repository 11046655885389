import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CopyFilled,
  DeleteFilled,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons'
import { Button, Divider, Space, Tooltip } from 'antd'
import FlexContainer from 'components/flex-container'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { useBlockManipulation } from 'services/hooks'
import { Spacing } from 'services/styles'
import MediaComponent from './components/media-component'
import PropertySelectComponent from './components/property-select-component'
import { PropertyTitleWrapper } from './style'

const PropertyHeader = () => {
  const activeBlockIndex = useSelector(CreatorSelectors.activeBlockIndex)
  const blocksLength = useSelector(CreatorSelectors.blocksLength)

  const {
    onDeleteClick,
    onDuplicateClick,
    setDeltaActiveIndex,
    setDeltaBlockOrder
  } = useBlockManipulation()

  const hasNext = activeBlockIndex < blocksLength - 1
  const hasPrevious = activeBlockIndex !== 0

  return (
    <PropertyTitleWrapper>
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <div>
          <Space>
            <h3 style={{ marginBottom: 0 }}>Q{activeBlockIndex + 1}</h3>
            <Tooltip title="Next question">
              <Button
                type="text"
                icon={<LeftOutlined />}
                size={'small'}
                disabled={!hasPrevious}
                onClick={() => setDeltaActiveIndex(-1)}
              />
            </Tooltip>
            <Tooltip title="Previous question">
              <Button
                type="text"
                icon={<RightOutlined />}
                size={'small'}
                disabled={!hasNext}
                onClick={() => setDeltaActiveIndex(1)}
              />
            </Tooltip>
          </Space>
        </div>

        <div>
          <Space>
            <Tooltip title="Move question up">
              <Button
                type="text"
                icon={<ArrowDownOutlined />}
                size={'small'}
                disabled={!hasNext}
                onClick={() => setDeltaBlockOrder(1)}
              />
            </Tooltip>
            <Tooltip title="Move question down">
              <Button
                type="text"
                icon={<ArrowUpOutlined />}
                size={'small'}
                disabled={!hasPrevious}
                onClick={() => setDeltaBlockOrder(-1)}
              />
            </Tooltip>
            <Tooltip title="Duplicate question">
              <Button
                type="text"
                icon={<CopyFilled />}
                size={'small'}
                onClick={onDuplicateClick}
              />
            </Tooltip>
            <Tooltip title="Delete question">
              <Button
                type="text"
                icon={<DeleteFilled />}
                size={'small'}
                onClick={onDeleteClick}
              />
            </Tooltip>
          </Space>
        </div>
      </FlexContainer>
      <PropertySelectComponent />
      <Divider />
      <MediaComponent />
    </PropertyTitleWrapper>
  )
}

export default React.memo(PropertyHeader)
