import FlexContainer from 'components/flex-container'
import React from 'react'
import { BorderRadius, Spacing } from 'services/styles'

interface TypeIndexIndicatorProps {
  index?: number
  icon: any
  color: string
  style?: any
  size?: number
}

const TypeIndexIndicator = ({
  icon: Icon,
  index,
  color,
  style,
  size = 18
}: TypeIndexIndicatorProps) => (
  <FlexContainer
    style={{
      backgroundColor: color,
      padding: Spacing.TINY / 2,
      borderRadius: BorderRadius.TINY,
      paddingLeft: Spacing.TINY,
      paddingRight: Spacing.TINY,
      ...style
    }}
  >
    <Icon style={{ fontSize: size }} />
    {index !== undefined && (
      <p
        style={{
          marginBottom: 0,
          marginLeft: Spacing.TINY,
          fontSize: 14,
          fontWeight: 600
        }}
      >
        {index + 1}
      </p>
    )}
  </FlexContainer>
)

export default TypeIndexIndicator
