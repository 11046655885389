import { Col, Row } from 'antd'
import FlexContainer from 'components/flex-container'

import React from 'react'
import { Color } from 'services/styles'
import SignInImage from 'static/images/auth/sign-in.png'

const AuthBase = ({ children }) => (
  <Row>
    <Col
      span={12}
      style={{
        backgroundColor: Color.WHITE,
        minHeight: '100vh',
        overflow: 'scroll'
      }}
    >
      <FlexContainer
        style={{ flex: 1, height: '100%' }}
        justifyContent="center"
      >
        {children}
      </FlexContainer>
    </Col>
    <Col span={12} style={{ backgroundColor: '#4C68AB' }}>
      <FlexContainer style={{ height: '100vh' }} justifyContent="center">
        <img
          src={SignInImage}
          style={{ width: '75%', minWidth: 300 }}
          alt={'sign-in'}
        />
      </FlexContainer>
    </Col>
  </Row>
)

export default AuthBase
