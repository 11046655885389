import { Tag, Tooltip } from 'antd'
import React from 'react'
import { RatingBlockProps } from './types'

const RatingTags = (props: RatingBlockProps): any[] => {
  const output = []
  if (props.numberOfStars) {
    output.push(
      <Tooltip overlay={'Number of stars'}>
        <Tag>{props.numberOfStars} stars</Tag>
      </Tooltip>
    )
  }
  return output
}

export default RatingTags
