import { Card } from 'antd'
import FlexContainer from 'components/flex-container'
import { BorderRadius, Color, Spacing } from 'services/styles'
import styled from 'styled-components'

export const RefWrapper = styled.div`
  position: relative;
  padding-bottom: ${Spacing.SMALL}px; ;
`
export const Wrapper = styled.div`
  transition: 0.2s all linear;
  background-color: ${(props: WrapperProps) =>
    props.active ? Color.WHITE : Color.DIRTY_WHITE};
  padding: ${Spacing.SMALL}px;
  border: solid 1px
    ${(props: WrapperProps) => (props.active ? Color.GREY : Color.DIRTY_WHITE)};
  border-radius: ${(props: WrapperProps) =>
    (!props.active && BorderRadius.SMALL) || 0}px;
  margin-left: ${(props: WrapperProps) => props.active && -Spacing.SMALL}px;
  margin-right: ${(props: WrapperProps) => props.active && -Spacing.SMALL}px;
  margin-bottom: ${Spacing.SMALL}px;

  &:hover {
    background-color: ${Color.WHITE};
    box-shadow: 7px 7px 10px -10px rgba(0, 0, 0, 0.2);
  }
`

interface WrapperProps {
  active: boolean
}

export const NumberRow = styled(FlexContainer)`
  margin-bottom: ${Spacing.SMALL}px;
`

export const TitleRow = styled(FlexContainer)``

export const QuestionNumber = styled.h3`
  margin-bottom: 0;
  margin-right: ${Spacing.SMALL}px;
`

export const ActionGroupWrapper = styled(Card)`
  margin-bottom: ${Spacing.SMALL}px;
`

export const BaseAnswerTitle = styled.h2`
  margin-bottom: ${Spacing.TINY}px;
  text-align: center;
  font-size: 2em;
  font-weight: 300;
  color: #1e2d4f;
`
