import { Tag, Tooltip } from 'antd'
import React from 'react'
import { BaseBlockProps } from './types'

const BaseTags = (props: BaseBlockProps): any[] => {
  const output = []
  if (props.media) {
    output.push(
      <Tooltip overlay={'Media is included'} key={'media'}>
        <Tag>Media</Tag>
      </Tooltip>
    )
  }
  return output
}

export default BaseTags
