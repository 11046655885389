import { message } from 'antd'
import getNextQuestionIndex from 'components/survey-client/root/get-next-question-index'
import { SurveyClientState } from 'components/survey-client/root/types'
import { BlockType } from 'components/survey-creator/question-blocks/base/types'
import { useState } from 'react'

const isAnswerValid = (data): boolean => {
  console.log(data)
  if (!data) {
    return false
  }
  if (Array.isArray(data)) {
    return data.length > 0
  }
  return !!data
}

const useHandleAnswerState = (
  props: SurveyClientState,
  onNewAnswer,
  onComplete
) => {
  const { blocks, logics } = props
  const [stagedAnswer, setStagedAnswer] = useState(null)
  const [answer, setAnswer] = useState([])
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const question = blocks[currentQuestionIndex]
  const totalQuestions = blocks.length

  const onAnswerUpdate = (stagedAnswer: any) => setStagedAnswer(stagedAnswer)

  const handleNext = async () => {
    if (
      question.type !== BlockType.STATEMENT &&
      question.type !== BlockType.SLIDER &&
      !isAnswerValid(stagedAnswer)
    ) {
      message.info('Please fill in the missing answers')
    } else {
      const newAnswerObject = {
        ref: question.blockId,
        type: question.type,
        answer: stagedAnswer
      }
      const setNewAnswer = [...answer, newAnswerObject]

      const newQuestionIndex = getNextQuestionIndex(
        blocks,
        currentQuestionIndex,
        totalQuestions,
        newAnswerObject,
        logics
      )

      await onNewAnswer(newAnswerObject)
      if (newQuestionIndex === totalQuestions) {
        await onComplete(setNewAnswer)
      }
      setAnswer(setNewAnswer)
      setCurrentQuestionIndex(newQuestionIndex)
      setStagedAnswer(null)
    }
  }

  const handleReset = () => {
    setStagedAnswer(null)
    setAnswer([])
    setCurrentQuestionIndex(0)
  }

  return {
    question,
    onAnswerUpdate,
    handleNext,
    currentQuestionIndex,
    totalQuestions,
    answer,
    handleReset
  }
}

export default useHandleAnswerState
