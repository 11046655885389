import { Input as AntdInput } from 'antd'
import { BorderRadius, Color } from 'services/styles'
import styled from 'styled-components'

export const Input = styled(AntdInput)`
  background-color: transparent;
  width: 100%;
  border: 0;
  outline: 0;
`

export const InputWrapper = styled.div`
  background-color: transparent;
  transition: 0.1s all linear;
  border-radius: ${BorderRadius.TINY}px;
  flex: 1;
  height: 30px;

  &:focus {
    background-color: ${Color.GREY};
  }

  &:hover {
    background-color: ${Color.GREY};
  }
`
