import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { GridColumn } from '@progress/kendo-react-grid'
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps'
import { Divider } from 'antd'
import AnalysisCardFooter from 'components/analysis-card-footer'
import DataGridSortable from 'components/data-grid-sortable'
import columnTooltipRenderer from 'components/survey-creator/question-blocks/analysis-custom-components/column-tooltip-renderer'
import { AnalysisResultsProps } from 'components/survey-creator/question-blocks/base/analysis'
import { ChartType } from 'components/survey-creator/question-blocks/base/types'
import React, { useState } from 'react'
import { notEmpty, toTableColumns } from 'services/helpers'
import { Color } from 'services/styles'
import {
  clickableRowRenderer,
  columnChartLabels,
  piechartLabelContent,
  sparklineRender
} from '../../analysis-custom-components'

const renderChartValue = (props: GridCellProps) => (
  <td className={'font-mono'}>
    {props.dataItem[props.field].toFixed(0)}
    {props.field.toLowerCase().includes('percent') && ' %'}
  </td>
)

const getColumns = (isSingle, hasBenchmark) =>
  [
    toTableColumns({ title: 'Answer', field: 'label' }),
    hasBenchmark
      ? toTableColumns({ title: 'Index', field: 'userPercentageIndex' })
      : null,
    toTableColumns({ title: 'Respondents (%)', field: 'userPercentage' }),
    toTableColumns({ title: 'Respondents', field: 'userCount' })
  ].filter(notEmpty)

interface SelectAnalysisCommonComponentProps extends AnalysisResultsProps {
  isSingle?: boolean
}

const SelectAnalysisCommonComponent = ({
  response,
  onFilterUpdate,
  filtered,
  isSingle = false,
  isBenchmarked
}: SelectAnalysisCommonComponentProps) => {
  const { results, numberOfResponses, type } = response
  const [mode, setMode] = useState(ChartType.COLUMN)

  const handleRowClick = e => {
    const answer = e.dataItem.label
    let newValue
    if (filtered.includes(answer)) newValue = filtered.filter(i => i !== answer)
    else newValue = [...filtered, answer]
    onFilterUpdate(response.blockId, newValue)
  }

  const dataGridColumns = getColumns(isSingle, isBenchmarked)

  const renderCell = title => {
    if (title === 'Index') return sparklineRender
    if (
      title.includes('Answers') ||
      title.includes('Respondents') ||
      title.includes('Count')
    )
      return renderChartValue
    return undefined
  }

  const rowRender = (trElement, props) =>
    clickableRowRenderer(trElement, props, filtered)

  const component = {
    [ChartType.COLUMN]: (
      <Chart transitions={false}>
        <ChartSeries>
          <ChartSeriesItem
            type="column"
            data={results.map(i => i.userPercentage)}
            name="Your Result"
            labels={{
              format: '{0:n1}%',
              visible: true,
              position: 'outsideEnd',
              background: 'transparent'
            }}
          />
          {isBenchmarked && (
            <ChartSeriesItem
              type="column"
              color={Color.GREY_1}
              data={results.map(i => i.userPercentageBenchmark)}
              name="Benchmark"
              labels={{
                format: '{0:n1}%',
                visible: true,
                position: 'outsideEnd',
                background: 'transparent'
              }}
            />
          )}
        </ChartSeries>
        <ChartTooltip render={columnTooltipRenderer} />
        <ChartCategoryAxis>
          <ChartCategoryAxisItem
            categories={results.map(i => i.label)}
            labels={{
              rotation: results.length > 10 ? -45 : 'auto',
              content: columnChartLabels
            }}
          />
        </ChartCategoryAxis>
        <ChartValueAxis>
          <ChartValueAxisItem title={{ text: 'Respondents (%)' }} />
        </ChartValueAxis>
      </Chart>
    ),
    [ChartType.GRID]: (
      <DataGridSortable
        data={results}
        onRowClick={handleRowClick}
        rowRender={rowRender}
      >
        {dataGridColumns.map((column, index) => (
          <GridColumn
            cell={renderCell(column.title)}
            field={column.field}
            title={column.title}
            key={index}
            width={index !== 0 && 160}
          />
        ))}
      </DataGridSortable>
    ),
    [ChartType.PIE]: (
      <Chart transitions={false}>
        <ChartTitle text={response.title} />
        <ChartLegend position="bottom" />
        <ChartTooltip format="{0:n1}%" />
        <ChartSeries>
          <ChartSeriesItem
            type="pie"
            data={results}
            field="answerPercentage"
            categoryField="answer"
            labels={{ visible: true, content: piechartLabelContent }}
          />
        </ChartSeries>
      </Chart>
    )
  }

  return (
    <>
      {component[mode]}
      <Divider />
      <AnalysisCardFooter
        options={[ChartType.COLUMN, ChartType.PIE, ChartType.GRID]}
        currentMode={mode}
        onClick={setMode}
        questionType={type}
        completed={numberOfResponses}
      />
    </>
  )
}

export default SelectAnalysisCommonComponent
