import React from 'react'
import BaseBlock from '../base/block'
import SelectBlock from '../sub-components/select-block-common-component'
import SelectTagsCommonComponent from '../sub-components/select-tags-common-component'

const MultiSelectBlock = props => (
  <BaseBlock {...props} tagsList={SelectTagsCommonComponent}>
    <SelectBlock {...props} />
  </BaseBlock>
)

export default MultiSelectBlock
