import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartSeries,
  ChartSeriesItem,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { GridColumn } from '@progress/kendo-react-grid'
import { Divider } from 'antd'
import AnalysisCardFooter from 'components/analysis-card-footer'
import DataGridSortable from 'components/data-grid-sortable'
import BaseAnalysis from 'components/survey-creator/question-blocks/base/analysis'
import { ChartType } from 'components/survey-creator/question-blocks/base/types'
import React, { useState } from 'react'
import { toTableColumns } from 'services/helpers'
import { Spacing } from 'services/styles'

const columns = [
  toTableColumns({ title: 'Answer', field: 'label' }),
  toTableColumns({ title: 'Answers (%)', field: 'answerPercentage' }),
  toTableColumns({ title: 'Count', field: 'answerCount' })
]

const SliderAnalysisComponent = ({ response }) => {
  const { results, numberOfResponses, type } = response
  const [mode, setMode] = useState(ChartType.NONE)

  const renderComponent = () => {}
  return (
    <>
      <DataGridSortable data={results}>
        {columns.map((column, index) => (
          <GridColumn
            field={column.field}
            title={column.title}
            key={index}
            width={index !== 0 && 150}
          />
        ))}
      </DataGridSortable>
      <div
        style={{
          marginTop: Spacing.LARGE
        }}
      >
        {mode === ChartType.COLUMN && (
          <Chart>
            <ChartSeries>
              <ChartSeriesItem
                type="column"
                data={results.map(i => i.userPercentage)}
              />
            </ChartSeries>

            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                maxDivisions={10}
                categories={results.map(i => i.label)}
                labels={{ rotation: 'auto' }}
              />
            </ChartCategoryAxis>

            <ChartValueAxis>
              <ChartValueAxisItem title={{ text: 'Answers' }} />
            </ChartValueAxis>
          </Chart>
        )}
      </div>

      <Divider />

      <AnalysisCardFooter
        options={[ChartType.COLUMN, ChartType.GRID]}
        currentMode={mode}
        onClick={setMode}
        questionType={type}
        completed={numberOfResponses}
      />
    </>
  )
}

const SliderAnalysis = props => (
  <BaseAnalysis {...props}>
    {/*<SliderAnalysisComponent {...props} />*/}
  </BaseAnalysis>
)

export default SliderAnalysis
