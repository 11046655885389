/**
 * This file was experimental, its currently not used
 */
import { useEffect, useState } from 'react'
import initSqlJs, { Database } from 'sql.js'

const sqlWasm = require('!!file-loader?name=sql-wasm-[contenthash].wasm!sql.js/dist/sql-wasm.wasm')

export const demographicKeys = [
  'state',
  'children',
  'age',
  'gender',
  'householdSize',
  'income',
  'maritalStatus'
]

export enum dbName {
  RAW_DATA = 'RAW_DATA',
  DATA = 'DATA'
}

const useDb = data => {
  const [db, setDb] = useState<Database>(null)

  const handleInitDb = async () => {
    const SQL = await initSqlJs({ locateFile: () => sqlWasm })
    const newDb = new SQL.Database()
    if (!data) return null

    const questionsIds = data.project.survey.blocks.map(i => i.blockId)
    const columns = [...questionsIds, ...demographicKeys]
    const columnsQuery = columns.map(i => `"${i}" text`).join(', ')
    const initTableQuery = `CREATE TABLE data
                            (
                                ${columnsQuery}
                            );`
    newDb.exec(initTableQuery)
    const insertQuery = data.responses.map(i => {
      const responseFlat = i.responses.map(j => ({
        column: j.ref,
        value: Array.isArray(j.answer)
          ? j.answer?.map(a => a?.label).join(', ')
          : j.answer
      }))

      const demographicsFlat = Object.entries(i.demographics).map(
        ([column, value]) => ({ column, value })
      )

      const columns = []
      const values = []
      const combined = [...responseFlat, ...demographicsFlat]
      for (const { column, value } of combined) {
        columns.push(`"${column}"`)
        values.push(`"${value}"`)
      }
      const allColumns = columns.join(', ')
      const allValues = values.join(', ')
      return `INSERT INTO data (${allColumns})
              VALUES (${allValues})`
    })
    newDb.exec(insertQuery.join(';\n'))
    setDb(newDb)
  }

  useEffect(() => {
    ;(async () => handleInitDb())()
    // eslint-disable-next-line
  }, [data])
  return db
}

export default useDb
