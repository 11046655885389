import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import {
  completeSurveyApi,
  getSurveyApi,
  postSurveyApi,
  startSurveyApi
} from 'services/api/answer'
import { promisedDelay } from 'services/helpers'

type useSurveyStateResponse = {
  surveyData: any
  surveyId: string
  answerId: string
  startSurvey: () => Promise<void>
  isLoading: boolean
  onNewAnswer: (answer: object) => Promise<void>
  onComplete: () => Promise<void>
  isQuotaReached: boolean
}

const previewAnswerId = 'PREVIEW'
const tempId = 'TEMP'

const useSurveyState = (): useSurveyStateResponse => {
  const location = useLocation()
  const history = useHistory()
  const [surveyData, setSurveyData] = useState<object>({})
  const [surveyId, setSurveyId] = useState<string>(undefined)
  const [answerId, setAnswerId] = useState<string>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [isPreview, setIsPreview] = useState(false)
  const [isQuotaReached, setIsQuotaReached] = useState(false)

  useEffect(() => {
    const splitLocation = location.pathname.split('/')
    if (splitLocation[1] === 'answer' && splitLocation?.[2].length === 24) {
      setSurveyId(splitLocation[2])
    }
  }, [location.pathname])

  useEffect(() => {
    if (location.search.includes('test')) setIsPreview(true)
  }, [location.search])

  const startSurvey = async () => {
    setAnswerId(tempId)
    setIsLoading(true)

    if (isPreview) {
      await promisedDelay(1000)
      setAnswerId(previewAnswerId)
    } else {
      const token = JSON.parse(
        Buffer.from(
          new URLSearchParams(location.search).get('token'),
          'base64'
        ).toString()
      )
      const { id } = await startSurveyApi(surveyId, token)
      setAnswerId(id)
    }
    setIsLoading(false)
  }

  const fetchSurvey = async () => {
    setIsLoading(true)
    try {
      const survey = await getSurveyApi(surveyId)
      setSurveyData(survey)
    } catch (e) {
      const errorMessage = e?.response?.data?.message
      if (errorMessage === 'Response quota reached') {
        setIsQuotaReached(true)
      }
      console.log(isQuotaReached)
    }

    setIsLoading(false)
  }

  const onNewAnswer = async (answer: any) => {
    if (isPreview) {
    } else {
      await postSurveyApi(answerId, answer)
    }
  }

  const onComplete = async () => {
    setIsLoading(true)
    if (isPreview) {
      setAnswerId(previewAnswerId)
      await promisedDelay(1000)
    } else {
      await completeSurveyApi(answerId)
    }
    history.replace(`/answer/complete?id=${answerId}`)
    setIsLoading(false)
  }

  useEffect(() => {
    if (surveyId) {
      ;(async () => fetchSurvey())()
    }
    // eslint-disable-next-line
  }, [surveyId])

  return {
    surveyData,
    surveyId,
    answerId,
    startSurvey,
    isLoading,
    onNewAnswer,
    onComplete,
    isQuotaReached
  }
}

export default useSurveyState
