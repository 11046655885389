import React from 'react'
import { GrDrag } from 'react-icons/all'

interface RankingAnswerDraggableProps {
  text: string
  index: number
  choices: string[]
  onChange: (index: number, newIndex: number) => void
}

const rankingSelection = (index, choices, onChange) => (
  <div className="border-gray-100 border-2 p-2 mr-3 bg-white">
    <select
      value={index + 1}
      onChange={value => onChange(index, +value.currentTarget.value - 1)}
    >
      {choices.map((i, index) => (
        <option key={`${index} - ${i}`} value={index + 1}>
          {index + 1}
        </option>
      ))}
    </select>
  </div>
)

const RankingAnswerDraggable = ({
  text,
  onChange,
  index,
  choices
}: RankingAnswerDraggableProps) => {
  return (
    <div className="flex flex-row rounded-lg border-gray-100 border-2 mb-3 p-3 items-center justify-between bg-white">
      <div className="flex flex-row items-center">
        {rankingSelection(index, choices, onChange)}
        <h2 className="font-medium text-lg">{text}</h2>
      </div>
      <GrDrag style={{ marginRight: 10 }} />
    </div>
  )
}

export default RankingAnswerDraggable
