import { Avatar, Button, Divider, Dropdown, Layout, Menu } from 'antd'
import { UserActions, UserSelectors } from 'data/user'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { Color, Spacing } from 'services/styles'
import UAskLogo from 'static/logo.png'
import MenuComponent from './menu'

const HeaderComponent = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(UserSelectors.user)

  const handleLogout = () => {
    dispatch(UserActions.userLogout())
    history.push('/login')
  }

  const menu = (
    <Menu>
      <div className="p-5 whitespace-nowrap">
        <div className="font-bold text-lg">
          {user?.firstName} {user?.lastName}
        </div>
        <div>{user?.email}</div>
      </div>
      <Divider className="m-0" />
      <Menu.Item onClick={handleLogout}>
        <Button type="text">Logout</Button>
      </Menu.Item>
    </Menu>
  )

  const isLoggedIn = !!user

  return (
    <Layout.Header
      style={{
        backgroundColor: Color.WHITE,
        borderBottom: `1px solid ${Color.GREY}`
      }}
    >
      <div className="flex flex-row h-full justify-between items-center">
        <div className="flex flex-row items-center">
          <Link to={'/'}>
            <img
              src={UAskLogo}
              style={{ height: 40, marginRight: Spacing.SMALL }}
              alt={'uask-logo'}
            />
          </Link>
          {isLoggedIn && <MenuComponent />}
        </div>

        {isLoggedIn ? (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <Button type="text" style={{ padding: 0, height: '100%' }}>
              <Avatar size="large">
                {user?.firstName?.[0]}
                {user?.lastName?.[0]}
              </Avatar>
            </Button>
          </Dropdown>
        ) : (
          <Button type="primary">
            <Link to={'/register'}>Register</Link>
          </Button>
        )}
      </div>
    </Layout.Header>
  )
}
export default HeaderComponent
