import { Card } from 'antd'
import React from 'react'
import { Spacing } from 'services/styles'
import { BaseBlockProps } from '../base/types'

interface BaseAnalysisProps {
  children: any
  index?: number
  onFilterUpdate: (questionId: string, values: string[]) => void
  filtered: string[]
  response: Response
  hasBenchmark?: boolean
}

export interface QuestionAnalysisResult {
  label: string
  answerCount: number
  answerPercentage: number
  userPercentage: number
  userCount: number
  answerPercentageIndex: number
  answerPercentageChange: number
  userPercentageIndex: number
  userPercentageChange: number
  answerPercentageBenchmark: number
  userPercentageBenchmark: number
}

interface Response extends BaseBlockProps {
  results: QuestionAnalysisResult[]
  numberOfResponses: number
}

export interface AnalysisResultsProps {
  response: Response
  onFilterUpdate: (questionId: string, values: string[]) => void
  filtered: string[]
  index?: number
  isBenchmarked: boolean
}

const BaseAnalysis = ({ response, children, index }: BaseAnalysisProps) => (
  <Card className="mb-3">
    <h2 className="mb-3 text-lg font-semibold">
      <span>Q{index + 1}</span>
      <span style={{ marginLeft: Spacing.TINY }}>{response.title}</span>
    </h2>
    {children}
  </Card>
)

export default BaseAnalysis
