enum AuthRouteConstants {
  LOGIN = '/login',
  REGISTER = '/register',
  ACTIVATE = '/activate',
  CONFIRM_ACCOUNT = '/confirm',
  RESET_PASSWORD = '/reset-password',
  FORGOT_PASSWORD = '/forgot-password'
}

export default AuthRouteConstants
