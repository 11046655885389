import { ClientAnswer, ClientComplete } from 'pages/client'
import { ClientRouteConstants } from 'routes/client'
import { RouteDefinition } from 'routes/types'

const CreatorRoutesDefinitions: RouteDefinition[] = [
  {
    path: ClientRouteConstants.ANSWER_COMPLETE,
    component: ClientComplete,
    exact: true
  },
  {
    path: ClientRouteConstants.ANSWER,
    component: ClientAnswer,
    exact: false
  }
]
export default CreatorRoutesDefinitions
