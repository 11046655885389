import { Modal } from 'data/overlay/types'
import { createAction } from 'redux-actions'
import { v4 as uuidv4 } from 'uuid'
import OverlayConstants from './constants'

export const showModal = (modal: Modal) =>
  createAction(OverlayConstants.SHOW_MODAL)({
    modal: { id: uuidv4(), ...modal, visible: true }
  })

export const hideModal = (id: string) =>
  createAction(OverlayConstants.HIDE_MODAL)({ id })

export const hideModalComplete = (id: string) =>
  createAction(OverlayConstants.HIDE_MODAL_COMPLETE)({ id })
