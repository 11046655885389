import React from 'react'
import { BaseBlockWithAnswers } from '../base/types'

const BaseAnswer = (props: BaseBlockWithAnswers) => {
  const { title, component: Component } = props
  return (
    <div className="flex flex-col flex-1">
      <h1 className={'font-medium text-center text-2xl mb-3'}>{title}</h1>
      {props.media && (
        <img
          src={props.media}
          className={'w-full mb-3'}
          style={{
            maxHeight: 300,
            objectFit: 'contain'
          }}
          alt={title}
        />
      )}
      <Component {...props} />
    </div>
  )
}

export default BaseAnswer
