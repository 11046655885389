import { Slider } from 'antd'
import { SliderMarks } from 'antd/es/slider'
import FlexContainer from 'components/flex-container'
import AnswerPrompt from 'components/survey-creator/question-blocks/answer-prompt'
import React from 'react'
import { Spacing } from 'services/styles'
import BaseAnswer from '../base/answer'
import { SliderAnswerProps } from './types'

const SliderAnswerComponent = (props: SliderAnswerProps) => {
  const { onAnswerUpdate, range, choices } = props
  const { min, max, step } = range

  const onValueChange = e => onAnswerUpdate(e)

  const marks = choices.reduce<SliderMarks>((acc, choice, index) => {
    let reIndexed
    if (index === 0) {
      reIndexed = min
    } else if (index === choices.length - 1) {
      reIndexed = max
    } else {
      reIndexed = ((max - min) / (choices.length - 1)) * index
    }
    acc[Math.floor(reIndexed)] = choice
    return acc
  }, {})

  return (
    <FlexContainer flexDirection="column" justifyContent={'center'}>
      <AnswerPrompt text={'DRAG BUTTON TO RATE'} />
      <div
        className="mx-8"
        style={{ width: `calc(100% - ${Spacing.SMALL * 4}px)` }}
      >
        <Slider
          onChange={onValueChange}
          min={min}
          max={max}
          step={step}
          included
          marks={marks}
          className="slider-answer"
        />
      </div>
    </FlexContainer>
  )
}

const SliderAnswer = props => (
  <BaseAnswer {...props} component={SliderAnswerComponent} />
)

export default SliderAnswer
