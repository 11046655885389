export enum BlockType {
  TEXT = 'TEXT',
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  RATING = 'RATING',
  RANKING = 'RANKING',
  SLIDER = 'SLIDER',
  MATRIX_SINGLE_SELECT = 'MATRIX_SINGLE_SELECT',
  STATEMENT = 'STATEMENT'
}

export const QuestionColor = {
  [BlockType.TEXT]: '#ffe6b2',
  [BlockType.MULTI_SELECT]: '#aece99',
  [BlockType.SINGLE_SELECT]: '#cd7ae0',
  [BlockType.RATING]: '#f65b5b',
  [BlockType.RANKING]: '#7798f1',
  [BlockType.SLIDER]: '#a2e0dc',
  [BlockType.MATRIX_SINGLE_SELECT]: '#bba2e0',
  [BlockType.STATEMENT]: '#ffb565'
}

export interface BaseBlockProps {
  title: string
  type: BlockType
  blockId?: string
  index?: number
  media: null
}

export const InitialBaseBlock = {
  title: null,
  media: null
}

export interface BaseBlockWithAnswers extends BaseBlockProps {
  component: any
  onAnswerUpdate: (answer: any) => void
}

export interface BaseBlockWithComponent extends BaseBlockProps {
  children?: JSX.Element
  tagsList?: any
}

export interface BlockDefinition {
  title: string
  color: string
  description: string
  type: BlockType
  initialProps: any
  icon: any
  operations?: Operation[]
  logicKey?: any
}

export interface BaseLogic {
  logicId: string
  ref: string
  type: BlockType
  logicOperation: LogicOperation
  to?: {
    ref: any
  }
  actionGroups: LogicActionGroup[]
}

export interface LogicActionGroup {
  actionGroupId: string
  actionOperation: ActionOperation
  actions: LogicAction[]
}

export interface LogicAction {
  actionId: string
  condition?: {
    operation: Operation
    field?: any
  }
}

export enum LogicOperation {
  AND = 'AND',
  OR = 'OR'
}

export enum ActionOperation {
  AND = 'AND',
  OR = 'OR'
}

export interface BaseLogicSubComponentProps {
  logicId: string
  action: LogicAction
  actionGroupId: string
}

export enum Operation {
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  CONTAINS = 'CONTAINS',
  DOES_NOT_CONTAINS = 'DOES_NOT_CONTAINS',
  BEGINS_WITH = 'BEGINS_WITH',
  END_WITH = 'END_WITH',
  LOWER_THAN = 'LOWER_THAN',
  LOWER_EQUAL_THEN = 'LOWER_EQUAL_THEN',
  GREATER_THEN = 'GREATER_THEN',
  GREATER_EQUAL_THAN = 'GREATER_EQUAL_THAN',
  IN_BETWEEN = 'IN_BETWEEN'
}

export const OperationToCanonical = {
  [Operation.EQUAL]: 'is equal to',
  [Operation.NOT_EQUAL]: 'is not equal to',
  [Operation.CONTAINS]: 'contains',
  [Operation.DOES_NOT_CONTAINS]: 'does not contain',
  [Operation.BEGINS_WITH]: 'begins with',
  [Operation.END_WITH]: 'ends with',
  [Operation.LOWER_THAN]: 'lower than',
  [Operation.LOWER_EQUAL_THEN]: 'lower or equal than',
  [Operation.GREATER_THEN]: 'greater than',
  [Operation.GREATER_EQUAL_THAN]: 'greater or equal then',
  [Operation.IN_BETWEEN]: 'in between'
}

export const SampleBaseLogic: BaseLogic = {
  logicId: '123',
  ref: '123',
  type: BlockType.SLIDER,
  logicOperation: LogicOperation.AND,
  to: {
    ref: '123'
  },
  actionGroups: [
    {
      actionGroupId: '123',
      actionOperation: ActionOperation.OR,
      actions: [
        {
          actionId: '123',

          condition: {
            operation: Operation.EQUAL,
            field: '123'
          }
        },
        {
          actionId: '123',
          condition: {
            operation: Operation.EQUAL,
            field: '123'
          }
        }
      ]
    }
  ]
}

export enum ChartType {
  COLUMN = 'COLUMN',
  PIE = 'PIE',
  GRID = 'GRID',
  WORD_CLOUD = 'WORD_CLOUD',
  NONE = 'NONE'
}
