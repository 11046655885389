import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from 'config'

const initSentry = (history: History) => {
  if (config.sentryDsn) {
    Sentry.init({
      dsn: config.sentryDsn,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(history)
        })
      ],
      tracesSampleRate: 1.0
    })
  }
}
export default initSentry
