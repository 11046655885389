import produce from 'immer'

const handleActionsImmer =
  <S, V = any>(actionsMap, defaultState: S) =>
  (state = defaultState, { type, payload }) =>
    produce<S, V>(state, draft => {
      const action = actionsMap[type]
      action && action(draft, payload)
    })

export default handleActionsImmer
