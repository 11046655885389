import { BaseLogic } from 'components/survey-creator/question-blocks/base/types'
import { createAction } from 'redux-actions'
import CreatorConstants from './constants'

export const putSurvey = state =>
  createAction(CreatorConstants.PUT_SURVEY)({ state })

export const putSurveyIsLoading = (isLoading: boolean) =>
  createAction(CreatorConstants.PUT_SURVEY_LOADING)({ isLoading })

export const putBlock = (options: object) =>
  createAction(CreatorConstants.PUT_BLOCK)(options)

export const initBlocks = (blocks: any[]) =>
  createAction(CreatorConstants.INIT_BLOCK)(blocks)

export const deleteBlock = (blockId: string) =>
  createAction(CreatorConstants.DELETE_BLOCK)({ blockId })

export const setActiveBlock = (activeBlockId: string) =>
  createAction(CreatorConstants.SET_ACTIVE_BLOCK)({ activeBlockId })

export const rearrangeBlock = (startIndex: number, endIndex: number) =>
  createAction(CreatorConstants.REARRANGE_BLOCK)({ startIndex, endIndex })

export const putLogic = (options: Partial<BaseLogic>) =>
  createAction(CreatorConstants.PUT_LOGIC)(options)

export const initLogics = (logics: any[]) =>
  createAction(CreatorConstants.INIT_LOGIC)(logics)

export const deleteLogic = (logicId: string) =>
  createAction(CreatorConstants.DELETE_LOGIC)({ logicId })
