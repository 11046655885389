import { CreatorActions } from 'data/actions'
import { CreatorConstants } from 'data/constants'
import { PUT_SURVEY_THROTTLE } from 'data/creator/constants'
import { sagaErrorFallback } from 'data/saga-fallback'
import { all, call, fork, put, throttle } from 'redux-saga/effects'
import { putSurveyApi } from 'services/api/survey'

function* handlePutSurvey({ payload: props }) {
  yield put(CreatorActions.putSurveyIsLoading(true))
  yield call(putSurveyApi, props.state.id, props.state)
  yield put(CreatorActions.putSurveyIsLoading(false))
}

function* watchPutSurvey() {
  yield throttle(
    PUT_SURVEY_THROTTLE,
    CreatorConstants.PUT_SURVEY,
    sagaErrorFallback,
    handlePutSurvey
  )
}

function* creatorSagaRoot() {
  yield all([fork(watchPutSurvey)])
}

export default creatorSagaRoot
