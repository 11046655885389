import {
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  Sparkline
} from '@progress/kendo-react-charts'
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps'

import React from 'react'
import { Color } from 'services/styles'

const indexColor = '#10B981'

const sparklineTooltipRender = props => (
  <span>{props?.points?.[0]?.value?.current}</span>
)

const renderSparkline = (props: GridCellProps) => {
  const value = props.dataItem[props.field]
  return (
    <td style={{ padding: 5, textOverflow: 'unset' }}>
      <Sparkline
        data={[[value, 100]]}
        type={'bullet'}
        seriesColors={['transparent']}
      >
        <ChartValueAxis>
          <ChartValueAxisItem
            majorGridLines={{ visible: false }}
            minorTicks={{ visible: false }}
            min={0}
            max={200}
            plotBands={[
              {
                from: 0,
                to: 200,
                color: Color.GREY_2 + '10'
              },
              {
                from: 0,
                to: value < 100 ? value : 100,
                color: Color.GREY_2 + '30'
              },
              {
                from: 100,
                to: value < 100 ? 100 : value > 200 ? 200 : value,
                color: indexColor
              }
            ]}
          />
        </ChartValueAxis>
        <ChartTooltip render={sparklineTooltipRender} />
      </Sparkline>
    </td>
  )
}
export default renderSparkline
