import { BorderRadius, Color, Spacing } from 'services/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${Color.CLICKABLE};
  font-size: 11px;
  color: ${Color.WHITE};
  padding: ${Spacing.TINY / 3}px ${Spacing.SMALL}px;
  border-radius: ${BorderRadius.MEDIUM * 2}px;
  margin-bottom: ${Spacing.SMALL * 1.5}px;
  align-self: center;
`
