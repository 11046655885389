import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistReducer, persistStore } from 'redux-persist'
import createCompressor from 'redux-persist-transform-compress'
import { PersistConfig } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import reduxThunk from 'redux-thunk'
import rootReducer from './reducers'
import * as sagas from './sagas'

const compressor = createCompressor()

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  blacklist: ['overlay', 'workspace', 'creator', 'user'],
  transforms: [compressor]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(reduxThunk, sagaMiddleware))
)

Object.values(sagas).forEach(sagaMiddleware.run.bind(sagaMiddleware))

const persistor = persistStore(store)

export { store, persistor }
