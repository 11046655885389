import { useEffect, useRef, useState } from 'react'

const useHover = ref => {
  const [value, setValue] = useState(false)
  const timerRef = useRef(null)
  useEffect(
    () => {
      const node = ref.current
      if (node) {
        const handleMouseOver = () => {
          if (timerRef.current) clearTimeout(timerRef.current)

          timerRef.current = setTimeout(() => setValue(true), 0)
        }
        const handleMouseOut = () => {
          if (timerRef.current) clearTimeout(timerRef.current)
          timerRef.current = setTimeout(() => setValue(false), 0)
        }

        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    // eslint-disable-next-line
    [ref.current] // Recall only if ref changes
  )
  return value
}

export default useHover
