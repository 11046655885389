import styled from 'styled-components'

export const HoverWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  transition: 0.1s all linear;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
`
