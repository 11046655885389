import {
  AutomatedMRDetails,
  AutomatedMRList,
  CustomSurveyDetails,
  CustomSurveyList,
  Interstitial
} from 'pages/templates'
import { RouteDefinition } from 'routes/types'
import TemplateRouteConstants from './constants'

const TemplateRoutesDefinition: RouteDefinition[] = [
  {
    path: TemplateRouteConstants.INTERSTITIAL,
    component: Interstitial,
    exact: true
  },
  {
    path: TemplateRouteConstants.MARKET_RESEARCH_LIST,
    component: AutomatedMRList,
    exact: true
  },
  {
    path: TemplateRouteConstants.MARKET_RESEARCH_DETAILS,
    component: AutomatedMRDetails,
    exact: false
  },
  {
    path: TemplateRouteConstants.CUSTOM_SURVEY_LIST,
    component: CustomSurveyList,
    exact: true
  },
  {
    path: TemplateRouteConstants.CUSTOM_SURVEY_DETAILS,
    component: CustomSurveyDetails,
    exact: false
  }
]
export default TemplateRoutesDefinition
