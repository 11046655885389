import { GridRowProps } from '@progress/kendo-react-grid'
import React from 'react'

const clickableRowRenderer = (
  trElement: React.ReactElement<HTMLTableRowElement>,
  props: GridRowProps,
  filtered: string[]
) => {
  const available = props.dataItem.label
  const isRowSelected = filtered.includes(available)
  const selectedRowStyle = { backgroundColor: 'rgba(0,0,0,0.2)' }
  const trProps: any = { style: isRowSelected ? selectedRowStyle : {} }
  return React.cloneElement(
    trElement,
    {
      ...trProps,
      className: trElement.props.className + ' selectable-row'
    },
    trElement.props.children
  )
}

export default clickableRowRenderer
