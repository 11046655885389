import { Select } from 'antd'
import FlexContainer from 'components/flex-container'
import React from 'react'
import { Color, Spacing } from 'services/styles'
import { BaseLogic, LogicActionGroup, LogicOperation } from '../types'
import ActionComponent from './action'
import { useHandleLogicChange } from './hooks'

interface ActionGroupProps {
  logic: BaseLogic
  actionGroup: LogicActionGroup
  component: any
  index: number
}

const ActionGroupComponent = ({
  logic,
  actionGroup,
  component,
  index
}: ActionGroupProps) => {
  const { handleBaseOperationChange } = useHandleLogicChange()

  return (
    <div>
      <div
        style={{ backgroundColor: Color.DIRTY_WHITE, padding: Spacing.SMALL }}
      >
        {actionGroup.actions.map((action, index) => (
          <FlexContainer justifyContent="flex-start" key={action.actionId}>
            <ActionComponent
              action={action}
              component={component}
              key={action.actionId}
              actionGroup={actionGroup}
              isFirst={index === 0}
              logicId={logic.logicId}
            />
          </FlexContainer>
        ))}
      </div>

      {index < logic?.actionGroups?.length - 1 && (
        <div style={{ margin: Spacing.SMALL, marginLeft: 0 }}>
          <Select
            style={{ width: '100px' }}
            value={logic.logicOperation}
            onChange={e => handleBaseOperationChange(logic.logicId, e)}
          >
            {Object.keys(LogicOperation).map(i => (
              <Select.Option value={i} key={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}

export default ActionGroupComponent
