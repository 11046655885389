import { Button, Col, Form, Input, Row, Typography } from 'antd'
import AuthBase from 'pages/auth/auth-base'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { resetPasswordApi } from 'services/api/auth'
import useSearchParams from 'services/helpers/search-params-to-object'
import { firebaseLogin } from 'services/integrations/firebase'
import { Spacing } from 'services/styles'
import UAskLogo from 'static/logo.png'

const { Title, Text } = Typography

const ForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [success, setSuccess] = useState(false)
  const history = useHistory()
  const { token } = useSearchParams()
  const { userId, code } = JSON.parse(atob(token))

  const onFinish = async ({ password }: any) => {
    setIsLoading(true)
    setHasError(false)
    try {
      const { email } = await resetPasswordApi(userId, code, password)
      setSuccess(true)
      setTimeout(async () => {
        await firebaseLogin(email, password)
        history.push('/')
      }, 1000)
    } catch (e) {
      setHasError(true)
      setIsLoading(false)
      console.log(e.message)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <AuthBase>
      <Row style={{ width: '100%', maxWidth: 600 }}>
        <Col span={18} offset={3}>
          <img
            src={UAskLogo}
            style={{ height: 60, marginBottom: Spacing.MEDIUM }}
            alt={'uask-logo'}
          />
          <Title level={2} style={{ marginBottom: Spacing.SMALL * 1.5 }}>
            Reset password
          </Title>

          {!success ? (
            <Form
              layout="vertical"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  }
                ]}
                hasFeedback
              >
                <Input.Password size={'large'} />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(
                        new Error(
                          'The two passwords that you entered do not match!'
                        )
                      )
                    }
                  })
                ]}
              >
                <Input.Password size={'large'} />
              </Form.Item>

              {hasError && (
                <div
                  style={{
                    marginBottom: Spacing.SMALL,
                    marginTop: Spacing.SMALL
                  }}
                >
                  <Text type="danger">Error</Text>
                </div>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size={'large'}
                  loading={isLoading}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Typography.Text style={{ marginBottom: Spacing.MEDIUM }}>
              Success! Redirecting you in 5 seconds...
            </Typography.Text>
          )}
        </Col>
      </Row>
    </AuthBase>
  )
}

export default ForgotPasswordPage
