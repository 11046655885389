import cloneDeep from 'lodash/cloneDeep'

const rearrangeArray = <T>(
  rawArray: T[],
  oldIndex: number,
  newIndex: number
): T[] => {
  const arr = cloneDeep(rawArray)
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}
export default rearrangeArray
