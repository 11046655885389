import { Checkbox, Input } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import FlexContainer from 'components/flex-container'
import AnswerPrompt from 'components/survey-creator/question-blocks/answer-prompt'
import produce from 'immer'
import React, { useState } from 'react'
import { Color } from 'services/styles'

import {
  SelectAnswerExtraProperties,
  SelectAnswerResponsePayload
} from './types'

interface MultipleSelectComponentProps {
  handleMultipleChange: (updatedData: SelectAnswerResponsePayload[]) => void
  choices: string[]
  other?: boolean
  noneOfTheAbove?: boolean
  values: SelectAnswerResponsePayload[]
}

const maxWidth = { width: '100%' }

interface StyledCheckboxProps extends CheckboxProps {
  isActive: boolean
}

const StyledCheckbox = (props: StyledCheckboxProps) => (
  <Checkbox
    {...props}
    className="p-3 mb-3 rounded-full w-full pl-4"
    style={{
      border: `1px solid ${props.isActive ? Color.CLICKABLE : Color.GREY_1}`
    }}
  >
    {props.children}
  </Checkbox>
)

const MultipleSelectComponent = ({
  handleMultipleChange,
  choices,
  other,
  noneOfTheAbove,
  values
}: MultipleSelectComponentProps) => {
  const [isOtherOpen, setIsOtherOpen] = useState(false)
  const valueLabels = values.map(i => i.label)

  const onChange = newValue => {
    if (
      !valueLabels.includes(SelectAnswerExtraProperties.NONE_OF_THE_ABOVE) &&
      newValue.includes(SelectAnswerExtraProperties.NONE_OF_THE_ABOVE)
    ) {
      setIsOtherOpen(false)
      handleMultipleChange([
        { label: SelectAnswerExtraProperties.NONE_OF_THE_ABOVE }
      ])
    } else {
      setIsOtherOpen(newValue.includes(SelectAnswerExtraProperties.OTHER))
      const mappedValues: SelectAnswerResponsePayload[] = newValue.map(
        label => {
          if (label === SelectAnswerExtraProperties.OTHER) {
            const extra = values.find(
              i => i.label === SelectAnswerExtraProperties.OTHER
            )?.extra

            return { label, extra }
          }
          return { label }
        }
      )
      handleMultipleChange(
        mappedValues.filter(
          i => i.label !== SelectAnswerExtraProperties.NONE_OF_THE_ABOVE
        )
      )
    }
  }

  const handleOtherChange = e => {
    const text = e.target.value
    const output = produce<SelectAnswerResponsePayload[]>(
      values,
      draftState => {
        const otherIndex = draftState.findIndex(
          i => i.label === SelectAnswerExtraProperties.OTHER
        )
        if (otherIndex > -1) {
          draftState[otherIndex] = { ...draftState[otherIndex], extra: text }
        }
        return draftState
      }
    )
    handleMultipleChange(output)
  }

  return (
    <div className="flex flex-col justify-center flex-1 w-full">
      <AnswerPrompt text={'SELECT ALL THAT APPLY'} />
      <FlexContainer justifyContent={'center'} style={{ width: '100%' }}>
        <Checkbox.Group
          onChange={onChange}
          style={maxWidth}
          value={valueLabels}
        >
          {choices.map((choice, index) => (
            <div className={'w-full'} key={`${index}-${choice}`}>
              <StyledCheckbox
                value={choice}
                isActive={valueLabels.includes(choice)}
              >
                {choice}
              </StyledCheckbox>
            </div>
          ))}
          {other && (
            <div className={'w-full'} key={SelectAnswerExtraProperties.OTHER}>
              <StyledCheckbox
                value={SelectAnswerExtraProperties.OTHER}
                isActive={valueLabels.includes(
                  SelectAnswerExtraProperties.OTHER
                )}
              >
                Other
              </StyledCheckbox>
            </div>
          )}
          {isOtherOpen && (
            <Input
              className={'mb-3 p-3'}
              placeholder="Type your answer"
              onChange={handleOtherChange}
              style={{ borderColor: Color.GREY }}
            />
          )}
          {noneOfTheAbove && (
            <div className={'w-full'} key={'noneOfTheAbove'}>
              <StyledCheckbox
                value={SelectAnswerExtraProperties.NONE_OF_THE_ABOVE}
                isActive={valueLabels.includes(
                  SelectAnswerExtraProperties.NONE_OF_THE_ABOVE
                )}
              >
                None of the above
              </StyledCheckbox>
            </div>
          )}
        </Checkbox.Group>
      </FlexContainer>
    </div>
  )
}

export default MultipleSelectComponent
