import React from 'react'
import { Route, Switch } from 'react-router'
import { AuthRouteDefinition } from 'routes/auth'

const AuthRouteComponent = () => (
  <Switch>
    {AuthRouteDefinition.map(({ component, path, exact }) => (
      <Route component={component} path={path} exact={exact} key={path} />
    ))}
  </Switch>
)

export default AuthRouteComponent
