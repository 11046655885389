import { store } from 'data'
import { UserActions } from 'data/actions'
import { FirebaseService } from 'services/integrations'

const handleFirebaseReady = () =>
  store.dispatch(UserActions.setFirebaseIsReady())

const handleFirebaseAuthStateChange = () =>
  store.dispatch(UserActions.firebaseAuthStateChange())

FirebaseService.firebaseAuthStateObserver({
  handleFirebaseReady,
  handleFirebaseAuthStateChange
})
