import { Spin, Typography } from 'antd'
import atob from 'atob'
import FlexContainer from 'components/flex-container'
import { UserSelectors } from 'data/user'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { activateUserApi } from 'services/api/auth'
import useSearchParams from 'services/helpers/search-params-to-object'
import { firebaseForceRefresh } from 'services/integrations/firebase'
import { Spacing } from 'services/styles'

const ConfirmAccountPage = () => {
  const history = useHistory()
  const { token } = useSearchParams()
  const { userId, code } = JSON.parse(atob(token))
  const user = useSelector(UserSelectors.user)
  const [isLoading, setIsLoading] = useState(true)

  if (user && user.isValidated) history.push('/')

  const handleActivateUser = async () => {
    setIsLoading(true)
    await activateUserApi(userId, code)
    setIsLoading(false)
    setTimeout(firebaseForceRefresh, 1000)
  }

  useEffect(() => {
    ;(async () => handleActivateUser())()
    // eslint-disable-next-line
  }, [])

  return (
    <FlexContainer
      justifyContent="center"
      flexDirection="column"
      style={{ height: '100%', width: '100%' }}
    >
      {isLoading ? (
        <>
          <Typography.Text style={{ marginBottom: Spacing.MEDIUM }}>
            Activating your account...
          </Typography.Text>
          <Spin size="large" />
        </>
      ) : (
        <Typography.Text style={{ marginBottom: Spacing.MEDIUM }}>
          Success! Redirecting you in 5 seconds...
        </Typography.Text>
      )}
    </FlexContainer>
  )
}

export default ConfirmAccountPage
