import { AnalysisHome } from 'pages/analysis'
import { AnalysisRouteConstants } from 'routes/analysis'
import { RouteDefinition } from 'routes/types'

const AnalysisRoutesDefinition: RouteDefinition[] = [
  {
    path: AnalysisRouteConstants.ANALYSIS,
    component: AnalysisHome,
    exact: true
  }
]

export default AnalysisRoutesDefinition
