import axios from 'axios'
import getApiRoute from 'services/api/common/get-api-route'

export const listWorkspaceApi = async () => {
  const { data } = await axios.get(getApiRoute(`/workspace/`))
  return data
}

export const createWorkspaceApi = async (name: string) => {
  const { data } = await axios.post(getApiRoute(`/workspace/`), { name })
  return data
}

export const putWorkspaceApi = async (workspaceId: string, props: object) => {
  const { data } = await axios.put(
    getApiRoute(`/workspace/${workspaceId}`),
    props
  )
  return data
}

export const deleteWorkspaceApi = async (workspaceId: string) => {
  await axios.delete(getApiRoute(`/workspace/${workspaceId}`))
}

const searchWorkspaceCancelApiToken = axios.CancelToken
let cancel

export const searchWorkspaceApi = async q => {
  if (q?.length < 1) return []
  try {
    cancel && cancel()
    const { data } = await axios.get(getApiRoute(`/workspace/search?q=${q}`), {
      cancelToken: new searchWorkspaceCancelApiToken(c => (cancel = c))
    })
    return data
  } catch (e) {
    if (axios.isCancel(e)) return null
    return []
  }
}
