import { config } from 'dotenv'

config()

export interface Config {
  firebaseConfig: FirebaseConfig
  apiBaseUrl?: string
  clientBaseUrl?: string
  environment: string
  sentryDsn?: string
  hotjarId?: number
  intercomAppId?: string
  mode: MODE
}

export interface FirebaseConfig {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
}

export enum ENVIRONMENT {
  Production = 'PRODUCTION',
  Staging = 'STAGING'
}

export enum MODE {
  Dashboard = 'DASHBOARD',
  Client = 'CLIENT'
}

const baseConfig = { environment: process.env.REACT_APP_ENV }

const configMap = {
  [MODE.Dashboard]: {
    [ENVIRONMENT.Production]: {
      firebaseConfig: {
        apiKey: 'AIzaSyCgJAhLYWC56fHfKwViekgY9aKk1f5PrTI',
        authDomain: 'uask-production.firebaseapp.com',
        projectId: 'uask-production',
        storageBucket: 'uask-production.appspot.com',
        messagingSenderId: '165433784605',
        appId: '1:165433784605:web:189d28da9a755bdf9c49f4',
        measurementId: 'G-7PTVPQ3E71'
      },
      sentryDsn:
        'https://bc38738bd75d4dae9d4f57d794188328@o78584.ingest.sentry.io/5892341',
      apiBaseUrl: 'https://api.uask.io/dashboard',
      hotjarId: 2533874,
      intercomAppId: 'f4v4sl79',
      mode: MODE.Dashboard,
      ...baseConfig
    },
    [ENVIRONMENT.Staging]: {
      firebaseConfig: {
        apiKey: 'AIzaSyAoL2BEMj5r1A5SBOR8LayDpnn_dKBs2gg',
        authDomain: 'uask-staging.firebaseapp.com',
        projectId: 'uask-staging',
        storageBucket: 'uask-staging.appspot.com',
        messagingSenderId: '944289894390',
        appId: '1:944289894390:web:92cacf809c668cbaed41d7',
        measurementId: 'G-WD5W0ZRLEW'
      },
      apiBaseUrl: 'https://staging.api.uask.io/dashboard',
      hotjarId: 2533875,
      intercomAppId: 'f4v4sl79',
      mode: MODE.Dashboard,
      ...baseConfig
    }
  },
  [MODE.Client]: {
    [ENVIRONMENT.Production]: {
      firebaseConfig: {
        apiKey: 'AIzaSyD3kROsSw0wJX9lNYvRri5H0y3jlNvtcdw',
        authDomain: 'uask-client-production.firebaseapp.com',
        projectId: 'uask-client-production',
        storageBucket: 'uask-client-production.appspot.com',
        messagingSenderId: '120252796478',
        appId: '1:120252796478:web:d6e60356e3055b59974b6f',
        measurementId: 'G-FNHHHFWR12'
      },
      sentryDsn:
        'https://15104284b88c4d1ba73771e1c85cca7d@o78584.ingest.sentry.io/5893745',
      apiBaseUrl: 'https://api.uask.io/dashboard',
      mode: MODE.Client,
      ...baseConfig
    },
    [ENVIRONMENT.Staging]: {
      firebaseConfig: {
        apiKey: 'AIzaSyCXZWJpCYt9OENQqibOXHuYs2PWZvv8dIk',
        authDomain: 'uask-client-staging.firebaseapp.com',
        projectId: 'uask-client-staging',
        storageBucket: 'uask-client-staging.appspot.com',
        messagingSenderId: '18352287168',
        appId: '1:18352287168:web:68c6b6846d9152dc48ec25',
        measurementId: 'G-LZ8ZM66CBF'
      },
      apiBaseUrl: 'https://staging.api.uask.io/dashboard',
      mode: MODE.Client,
      ...baseConfig
    }
  }
}

const environmentConfig =
  configMap[process.env.REACT_APP_MODE][process.env.REACT_APP_ENV]

export default environmentConfig
