import { Modal } from 'antd'
import { OverlayActions } from 'data/actions'
import { OverlaySelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

const OverlayComponent = () => {
  const modal = useSelector(OverlaySelectors.modal)
  const dispatch = useDispatch()

  const handleCloseComplete = () =>
    dispatch(OverlayActions.hideModalComplete(modal.id))

  if (modal) {
    const onCancel = e => {
      if (modal.onCancel) {
        return modal.onCancel(e)
      } else {
        dispatch(OverlayActions.hideModal(modal.id))
      }
    }
    return (
      <Modal
        {...modal}
        onCancel={onCancel}
        destroyOnClose
        afterClose={handleCloseComplete}
      >
        {React.cloneElement(modal.content, {
          modalId: modal.id
        })}
      </Modal>
    )
  }
  return null
}
export default OverlayComponent
