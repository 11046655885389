import { Select } from 'antd'
import FlexContainer from 'components/flex-container'
import { BlockType } from 'components/survey-creator/question-blocks/base/types'
import { BlocksDefinition } from 'components/survey-creator/question-blocks/index'
import TypeIndexIndicator from 'components/survey-creator/type-index-indicator'
import { CreatorActions, CreatorSelectors } from 'data/creator'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spacing } from 'services/styles'

const PropertySelectComponent = () => {
  const activeBlock = useSelector(CreatorSelectors.activeBlock)
  const dispatch = useDispatch()

  const handleBlockChange = (newType: BlockType) => {
    const initialProps = BlocksDefinition[newType].blockDefinition.initialProps
    dispatch(
      CreatorActions.putBlock({
        ...initialProps,
        blockId: activeBlock.blockId,
        title: activeBlock.title
      })
    )
  }

  return (
    <Select
      value={activeBlock.type}
      style={{ width: '100%' }}
      onChange={handleBlockChange}
    >
      {Object.values(BlocksDefinition).map(({ blockDefinition }) => {
        const { type, title } = blockDefinition
        return (
          <Select.Option value={type} label={title} key={title}>
            <FlexContainer justifyContent={'flex-start'}>
              <TypeIndexIndicator
                {...blockDefinition}
                size={10}
                style={{
                  padding: Spacing.TINY / 2,
                  paddingLeft: Spacing.TINY / 2,
                  paddingRight: Spacing.TINY / 2
                }}
              />
              <p
                style={{
                  marginBottom: 0,
                  marginLeft: Spacing.TINY,
                  fontSize: 14,
                  fontWeight: 600
                }}
              >
                {title}
              </p>
            </FlexContainer>
          </Select.Option>
        )
      })}
    </Select>
  )
}
export default PropertySelectComponent
