import { Radio } from 'antd'
import TextInputClickable from 'components/survey-creator/text-input-clickable'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import cloneDeep from 'lodash/cloneDeep'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useParamSelector } from 'services/hooks'
import BaseBlock from '../base/block'
import MatrixRow from './matrix-row'
import { MatrixProps } from './types'

const MatrixBlockComponent: FC<MatrixProps> = props => {
  const dispatch = useDispatch()

  const blockState = useParamSelector(
    CreatorSelectors.getBlockById,
    props.blockId
  )

  const onHeaderChange = index => event => {
    const newText = event.target.value
    const newHeader = cloneDeep(blockState.headers)
    newHeader[index] = newText
    dispatch(CreatorActions.putBlock({ ...blockState, headers: newHeader }))
  }

  const onAddNewRow = () =>
    dispatch(
      CreatorActions.putBlock({
        ...blockState,
        choices: [...blockState.choices, `Row ${blockState.choices.length + 1}`]
      })
    )

  const onAddNewColumn = () =>
    dispatch(
      CreatorActions.putBlock({
        ...blockState,
        headers: [...blockState.headers, `Col ${blockState.headers.length + 1}`]
      })
    )

  const onRowChange = index => event => {
    const newText = event.target.value
    const newChoice = cloneDeep(blockState.choices)
    newChoice[index] = newText
    dispatch(CreatorActions.putBlock({ ...blockState, choices: newChoice }))
  }

  const numberOfColumns = props.headers.length

  return (
    <div className={'matrix-table'}>
      <table>
        <tr>
          {Array(numberOfColumns)
            .fill(0)
            .map(_ => (
              <td />
            ))}
          <p
            className={'cursor-pointer underline p-3 text-right'}
            onClick={onAddNewColumn}
          >
            Add Column
          </p>
        </tr>

        <tr>
          <th />
          {props.headers.map((i, index) => (
            <th>
              <TextInputClickable
                value={i}
                onChange={onHeaderChange(index)}
                className={'text-center'}
              />
            </th>
          ))}
        </tr>
        {props.choices.map((i, index) => (
          <tr>
            <td>
              <MatrixRow
                value={i}
                index={index}
                onRowChange={onRowChange(index)}
                state={blockState}
                disabledRearrange={false}
              />
            </td>
            {Array(numberOfColumns)
              .fill(0)
              .map((i, index) => (
                <td>
                  <Radio key={index} disabled />
                </td>
              ))}
          </tr>
        ))}
        <p className={'cursor-pointer underline p-3'} onClick={onAddNewRow}>
          Add Row
        </p>
      </table>
    </div>
  )
}

const MatrixBlock = props => (
  <BaseBlock {...props}>
    <MatrixBlockComponent {...props} />
  </BaseBlock>
)

export default MatrixBlock
