import { combineReducers } from 'redux'
import { rearrangeArray } from 'services/helpers'
import handleActionsImmer from 'services/integrations/immer'
import { CreatorConstants } from '.'
import {
  CreatorBaseState,
  CreatorBlockState,
  CreatorLogicState,
  CreatorState
} from './types'

const creator = handleActionsImmer<CreatorBaseState>(
  {
    [CreatorConstants.PUT_SURVEY]: (state, payload) => {
      state.state = payload.state
    },
    [CreatorConstants.PUT_SURVEY_LOADING]: (state, { isLoading }) => {
      state.isLoading = isLoading
    }
  },
  { isLoading: false, state: {} }
)

const blocks = handleActionsImmer<CreatorBlockState>(
  {
    [CreatorConstants.INIT_BLOCK]: (state, payload) => {
      state.blocks = payload
      state.activeBlockId = null
    },
    [CreatorConstants.PUT_BLOCK]: (state, payload) => {
      const foundIndex = state.blocks.findIndex(
        i => i.blockId === payload.blockId
      )
      if (foundIndex > -1) {
        state.blocks[foundIndex] = {
          ...state.blocks[foundIndex],
          ...payload
        }
      } else {
        state.blocks.push(payload)
      }
      state.activeBlockId = payload.blockId
    },
    [CreatorConstants.DELETE_BLOCK]: (state, payload) => {
      const activeBlockIndex = state.blocks.findIndex(
        i => i.blockId === state.activeBlockId
      )
      state.blocks = state.blocks.filter(i => i.blockId !== payload.blockId)
      if (activeBlockIndex <= state.blocks.length - 1) {
        state.activeBlockId = state.blocks[activeBlockIndex].blockId
      } else {
        state.activeBlockId =
          state.blocks[activeBlockIndex - 1]?.blockId || null
      }
    },
    [CreatorConstants.REARRANGE_BLOCK]: (state, payload) => {
      state.blocks = rearrangeArray(
        state.blocks,
        payload.startIndex,
        payload.endIndex
      )
    },
    [CreatorConstants.SET_ACTIVE_BLOCK]: (state, payload) => {
      state.activeBlockId = payload.activeBlockId
    }
  },
  { blocks: [], activeBlockId: null, isReady: false }
)

const logics = handleActionsImmer<CreatorLogicState>(
  {
    [CreatorConstants.INIT_LOGIC]: (state, payload) => {
      state.logics = payload
    },
    [CreatorConstants.PUT_LOGIC]: (state, payload) => {
      const foundIndex = state.logics.findIndex(
        i => i.logicId === payload.logicId
      )
      if (foundIndex > -1) {
        state.logics[foundIndex] = {
          ...state.logics[foundIndex],
          ...payload
        }
      } else {
        state.logics.push(payload)
      }
    },
    [CreatorConstants.DELETE_LOGIC]: (state, payload) => {
      state.logics = state.logics.filter(i => i.logicId !== payload.logicId)
    },
    [CreatorConstants.DELETE_BLOCK]: (state, payload) => {
      const blockId = payload.blockId
      state.logics = state.logics.map(logic => {
        if (logic?.to?.ref === blockId) {
          return { ...logic, to: { ref: null } }
        }
        return logic
      })
      return state
    }
  },
  { logics: [], isReady: false }
)

export const creatorReducer = combineReducers<CreatorState>({
  creator,
  blocks,
  logics
})

export default creatorReducer
