import { BaseBlockWithAnswers } from 'components/survey-creator/question-blocks/base/types'
import { SelectBlockProps } from '../select-block-common-component/types'

export type SelectAnswerProps = SelectBlockProps & BaseBlockWithAnswers

export enum SelectAnswerExtraProperties {
  OTHER = 'other',
  NONE_OF_THE_ABOVE = 'noneOfTheAbove'
}

export type SelectAnswerResponsePayload = { label: string; extra?: string }
