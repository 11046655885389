import FillLoader from 'components/fill-loader'
import RootComponent from 'components/root-component'
import config, { MODE } from 'config'
import 'data/user/userObservable'
import React, { Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router'
import * as RouteDefinitions from 'routes'
import { AuthRouteComponent } from 'routes/auth'
import { ClientRouteDefinition } from 'routes/client'
import { useHandleAuthRouting } from 'routes/root/hooks'

export const whiteListedRoutes = ['/analysis', '/terms-of-use', '/answer']

const clientRoutes = Object.values(ClientRouteDefinition).map(
  ({ component, path, exact }) => (
    <Route component={component} path={path} exact={exact} key={path} />
  )
)
const dashboardRoutes = Object.values(RouteDefinitions)
  .flat()
  .map(({ component, path, exact }) => (
    <Route component={component} path={path} exact={exact} key={path} />
  ))

const RootRouteComponent = () => {
  const [user, isFirebaseReady, isFetchingUser] = useHandleAuthRouting(
    config.mode === MODE.Dashboard
  )

  const location = useLocation()

  const isWhiteListedRoute = whiteListedRoutes.some(i =>
    location.pathname.startsWith(i)
  )

  if (!isFirebaseReady || isFetchingUser) {
    return <FillLoader text="Setting up your workspace..." />
  }

  switch (config.mode) {
    case MODE.Dashboard:
      if (!user && !isWhiteListedRoute) {
        return <AuthRouteComponent />
      } else {
        return (
          <RootComponent>
            <Suspense fallback={<FillLoader text="Loading..." />}>
              <Switch>
                {dashboardRoutes}
                {clientRoutes}
              </Switch>
            </Suspense>
          </RootComponent>
        )
      }
    case MODE.Client:
      return (
        <Suspense fallback={<FillLoader text="Loading..." />}>
          <Switch>{clientRoutes}</Switch>
        </Suspense>
      )
  }
}

export default RootRouteComponent
