import { BaseBlockWithAnswers } from 'components/survey-creator/question-blocks/base/types'
import MultipleSelectComponent from 'components/survey-creator/question-blocks/sub-components/select-answer-common-component/multiple'
import SingleSelectAnswer from 'components/survey-creator/question-blocks/sub-components/select-answer-common-component/single'
import {
  SelectAnswerProps,
  SelectAnswerResponsePayload
} from 'components/survey-creator/question-blocks/sub-components/select-answer-common-component/types'
import shuffle from 'lodash/shuffle'
import React, { useEffect, useState } from 'react'

const SelectAnswerCommonComponent = (
  props: SelectAnswerProps & { single?: boolean } & BaseBlockWithAnswers
) => {
  const { choices, single, randomize, onAnswerUpdate } = props
  const [randomChoices, setRandomChoices] = useState<any[]>([])

  const [selectedChoices, setSelectedChoices] = useState<
    SelectAnswerResponsePayload[]
  >([])

  useEffect(() => {
    if (randomize) setRandomChoices(shuffle(choices))
    else setRandomChoices(choices)
  }, [choices, randomize])

  useEffect(() => {
    onAnswerUpdate(selectedChoices)
    // eslint-disable-next-line
  }, [selectedChoices])

  if (single) {
    const handleSingleChange = (e: SelectAnswerResponsePayload) =>
      setSelectedChoices([e])

    return (
      <SingleSelectAnswer
        value={selectedChoices}
        handleSingleChange={handleSingleChange}
        {...props}
        choices={randomChoices}
      />
    )
  } else {
    const handleMultipleChange = (
      updatedChoice: SelectAnswerResponsePayload[]
    ) => setSelectedChoices(updatedChoice)

    return (
      <MultipleSelectComponent
        values={selectedChoices}
        handleMultipleChange={handleMultipleChange}
        {...props}
        choices={randomChoices}
      />
    )
  }
}

export default SelectAnswerCommonComponent
