import { Radio } from 'antd'
import React, { useEffect, useState } from 'react'
import AnswerPrompt from '../answer-prompt'
import BaseAnswer from '../base/answer'
import { MatrixAnswerProps } from './types'

// label is the col
// ref is the row
const MatrixAnswerComponent = (props: MatrixAnswerProps) => {
  const [answerState, setAnswerState] = useState({})
  const numberOfColumns = props.headers.length

  const onRadioChange = (choice, index) => () => {
    setAnswerState(answerState => ({
      ...answerState,
      [choice]: props.headers[index]
    }))
  }

  useEffect(() => {
    props.onAnswerUpdate(
      Object.entries(answerState).map(([key, value]) => ({
        label: value,
        ref: key
      }))
    )
    // eslint-disable-next-line
  }, [answerState])

  return (
    <div className="flex flex-col justify-center w-full">
      <AnswerPrompt text={'SELECT ONE ANSWER PER ROW'} />
      <div className={'matrix-table mb-3'}>
        <table className={'m-auto'}>
          <tr>
            <th />
            {props.headers.map((i, _) => (
              <th>
                <p className={'text-center p-2'}>{i}</p>
              </th>
            ))}
          </tr>
          {props.choices.map((choice, _) => (
            <tr>
              <td className={'p-2'}>
                <p>{choice}</p>
              </td>
              {Array(numberOfColumns)
                .fill(0)
                .map((_, index) => (
                  <td className={'p-2'}>
                    <Radio
                      key={index}
                      checked={answerState[choice] === props.headers[index]}
                      onClick={onRadioChange(choice, index)}
                    />
                  </td>
                ))}
            </tr>
          ))}
        </table>
      </div>
    </div>
  )
}
const MatrixAnswer = props => (
  <BaseAnswer {...props} single component={MatrixAnswerComponent} />
)

export default MatrixAnswer
