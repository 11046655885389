import { TableOutlined } from '@ant-design/icons'
import {
  BaseBlockProps,
  BaseBlockWithAnswers,
  BlockDefinition,
  BlockType,
  InitialBaseBlock,
  Operation,
  QuestionColor
} from '../base/types'

export interface MatrixProps extends BaseBlockProps {
  headers: string[]
  choices: string[]
}

export type MatrixAnswerProps = MatrixProps & BaseBlockWithAnswers

const MatrixInitialProps: MatrixProps = {
  ...InitialBaseBlock,
  type: BlockType.MATRIX_SINGLE_SELECT,
  choices: ['Row 1'],
  headers: ['Col 1']
}

const MatrixDefinition: BlockDefinition = {
  title: 'Matrix',
  description: 'Rate a list of items',
  color: QuestionColor[BlockType.MATRIX_SINGLE_SELECT],
  icon: TableOutlined,
  type: BlockType.MATRIX_SINGLE_SELECT,
  initialProps: MatrixInitialProps,
  operations: [Operation.EQUAL, Operation.NOT_EQUAL]
}

export { MatrixDefinition }
