import { GridColumnProps } from '@progress/kendo-react-grid'

interface ToTableColumnsProps extends GridColumnProps {
  title: string
  field?: string
}

const toTableColumns = ({
  title,
  field
}: ToTableColumnsProps): GridColumnProps => ({
  title,
  field: field || title.toLowerCase()
})

export default toTableColumns
