import { CheckOutlined } from '@ant-design/icons'
import {
  BlockDefinition,
  BlockType,
  Operation,
  QuestionColor
} from '../base/types'
import {
  InitialSelectBlockProps,
  SelectBlockProps
} from '../sub-components/select-block-common-component/types'

const MultiSelectBlockInitialProps: SelectBlockProps = {
  ...InitialSelectBlockProps,
  type: BlockType.MULTI_SELECT
}

const MultiSelectBlockDefinition: BlockDefinition = {
  title: 'Multiple Selection',
  description: 'For multiple answers',
  color: QuestionColor[BlockType.MULTI_SELECT],
  icon: CheckOutlined,
  type: BlockType.MULTI_SELECT,
  initialProps: MultiSelectBlockInitialProps,
  operations: [Operation.EQUAL, Operation.NOT_EQUAL]
}

export { MultiSelectBlockDefinition }
