import { BlockType } from './base/types'

import {
  MatrixAnalysis,
  MatrixAnswer,
  MatrixBlock,
  MatrixDefinition,
  MatrixLogic,
  MatrixProperties
} from './matrix-single-select'

import {
  MultiSelectAnalysis,
  MultiSelectAnswer,
  MultiSelectBlock,
  MultiSelectBlockDefinition,
  MultiSelectLogic,
  MultiSelectProperties
} from './multi-select'

import {
  RankingAnalysis,
  RankingAnswer,
  RankingBlock,
  RankingDefinition,
  RankingLogic,
  RankingProperties
} from './ranking'

import {
  RatingAnalysis,
  RatingAnswer,
  RatingBlock,
  RatingBlockDefinition,
  RatingLogic,
  RatingProperties
} from './rating'

import {
  SingleSelectAnalysis,
  SingleSelectAnswer,
  SingleSelectBlock,
  SingleSelectBlockDefinition,
  SingleSelectLogic,
  SingleSelectProperties
} from './single-select'

import {
  SliderAnalysis,
  SliderAnswer,
  SliderBlock,
  SliderBlockDefinition,
  SliderLogic,
  SliderProperties
} from './slider'

import {
  StatementAnalysis,
  StatementAnswer,
  StatementBlock,
  StatementBlockDefinition,
  StatementLogic,
  StatementProperties
} from './statement'

import {
  TextAnalysis,
  TextAnswer,
  TextBlock,
  TextBlockDefinition,
  TextLogic,
  TextProperties
} from './text'

export const BlocksDefinition = {
  [BlockType.TEXT]: {
    editorComponent: TextBlock,
    blockDefinition: TextBlockDefinition,
    propertyComponent: TextProperties,
    answerComponent: TextAnswer,
    logicComponent: TextLogic,
    analysisComponent: TextAnalysis
  },
  [BlockType.MULTI_SELECT]: {
    editorComponent: MultiSelectBlock,
    blockDefinition: MultiSelectBlockDefinition,
    propertyComponent: MultiSelectProperties,
    answerComponent: MultiSelectAnswer,
    logicComponent: MultiSelectLogic,
    analysisComponent: MultiSelectAnalysis
  },
  [BlockType.SINGLE_SELECT]: {
    editorComponent: SingleSelectBlock,
    blockDefinition: SingleSelectBlockDefinition,
    propertyComponent: SingleSelectProperties,
    answerComponent: SingleSelectAnswer,
    logicComponent: SingleSelectLogic,
    analysisComponent: SingleSelectAnalysis
  },
  [BlockType.RATING]: {
    editorComponent: RatingBlock,
    blockDefinition: RatingBlockDefinition,
    propertyComponent: RatingProperties,
    answerComponent: RatingAnswer,
    logicComponent: RatingLogic,
    analysisComponent: RatingAnalysis
  },
  [BlockType.RANKING]: {
    editorComponent: RankingBlock,
    blockDefinition: RankingDefinition,
    propertyComponent: RankingProperties,
    answerComponent: RankingAnswer,
    logicComponent: RankingLogic,
    analysisComponent: RankingAnalysis
  },
  [BlockType.SLIDER]: {
    editorComponent: SliderBlock,
    blockDefinition: SliderBlockDefinition,
    propertyComponent: SliderProperties,
    answerComponent: SliderAnswer,
    logicComponent: SliderLogic,
    analysisComponent: SliderAnalysis
  },
  [BlockType.MATRIX_SINGLE_SELECT]: {
    editorComponent: MatrixBlock,
    blockDefinition: MatrixDefinition,
    propertyComponent: MatrixProperties,
    answerComponent: MatrixAnswer,
    logicComponent: MatrixLogic,
    analysisComponent: MatrixAnalysis
  },
  [BlockType.STATEMENT]: {
    editorComponent: StatementBlock,
    blockDefinition: StatementBlockDefinition,
    propertyComponent: StatementProperties,
    answerComponent: StatementAnswer,
    logicComponent: StatementLogic,
    analysisComponent: StatementAnalysis
  }
}
