import axios from 'axios'
import getApiRoute from 'services/api/common/get-api-route'

export const getSurveyApi = async (surveyId: string) => {
  const { data } = await axios.get(getApiRoute(`/answer/${surveyId}`))
  return data
}

export const startSurveyApi = async (projectId: string, props: object = {}) => {
  const { data } = await axios.post(getApiRoute(`/answer/`), {
    ...props,
    projectId
  })
  return data
}
export const postSurveyApi = async (answerId: string, props) => {
  const { data } = await axios.post(getApiRoute(`/answer/${answerId}`), props)
  return data
}

export const completeSurveyApi = async (answerId: string) => {
  const { data } = await axios.post(getApiRoute(`/answer/complete/${answerId}`))
  return data
}
