import { GridColumn } from '@progress/kendo-react-grid'
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps'
import { Divider } from 'antd'
import AnalysisCardFooter from 'components/analysis-card-footer'
import DataGridSortable from 'components/data-grid-sortable'
import BaseAnalysis, {
  AnalysisResultsProps
} from 'components/survey-creator/question-blocks/base/analysis'
import { ChartType } from 'components/survey-creator/question-blocks/base/types'
import React, { useState } from 'react'
import { toTableColumns } from 'services/helpers'

const renderChartValue = (props: GridCellProps, handleCellClick, filtered) => {
  const key = `${props.dataItem.label}_${props.field}`
  const isCellSelected = filtered.includes(key)
  const baseLevel =
    (props.dataItem[props.columnIndex].answerPercentage / 100) * 0.8
  const selectedLevel = baseLevel + 0.3

  const cellStyle = {
    backgroundColor: `rgba(72,140,189,${
      isCellSelected ? selectedLevel : baseLevel
    })`
  }
  const tdProps: any = { style: { cursor: 'pointer', ...cellStyle } }

  const renderContent = () => {
    if (props?.dataItem?.[props?.field]) {
      const userCount = props?.dataItem?.[props?.field]['userCount']
      const userPercentage = props?.dataItem?.[props?.field]['userPercentage']
      return (
        <>
          <div className={'font-bold'}>{userCount}</div>
          <div className={`italic text-gray-${isCellSelected ? '200' : '500'}`}>
            ({userPercentage.toFixed(0)}%)
          </div>
        </>
      )
    } else {
      return (
        <>
          <div className={'font-bold'}>0</div>
          <div className={'italic text-gray-400'}>(0%)</div>
        </>
      )
    }
  }

  return (
    <td
      className={'font-mono text-center matrix-cell'}
      onClick={handleCellClick(props)}
      {...tdProps}
    >
      {renderContent()}
    </td>
  )
}

interface MatrixAnalysisCommonComponentProps extends AnalysisResultsProps {}

const matrixResultsToData = (results, headers) => {
  const columns = [
    toTableColumns({ title: 'Answer', field: 'label' }),
    ...headers.map(i => toTableColumns({ title: i, field: i }))
  ]
  const aggregateRows = results.reduce((acc, cur) => {
    const [row, col] = cur.label.split('_')
    const renamedCur = { ...cur, label: col }
    if (row in acc) {
      acc[row] = [...acc[row], renamedCur]
    } else {
      acc[row] = [renamedCur]
    }
    return acc
  }, {})
  const rows = Object.entries<any[]>(aggregateRows).map(([row, columns]) => {
    const columnsObject = columns.reduce((acc, cur) => {
      acc[cur.label] = cur
      return acc
    }, {})
    return {
      label: row,
      ...columnsObject
    }
  })

  return { columns, rows }
}
const MatrixAnalysisCommonComponent = ({
  response,
  onFilterUpdate,
  filtered
}: MatrixAnalysisCommonComponentProps) => {
  //  @ts-ignore
  const { results, numberOfResponses, type, headers, choices } = response
  const [mode, setMode] = useState(ChartType.GRID)

  const handleCellClick = props => () => {
    const col = props.field
    const row = props.dataItem.label
    const filterKey = `${row}_${col}`

    let newValue
    if (filtered.includes(filterKey))
      newValue = filtered.filter(i => i !== filterKey)
    else newValue = [...filtered, filterKey]
    onFilterUpdate(response.blockId, newValue)
  }

  const renderCell = title => props => {
    if (!title.includes('Answer')) {
      return renderChartValue(props, handleCellClick, filtered)
    }
    return <td>{props.dataItem[props.field]}</td>
  }

  const { columns, rows } = matrixResultsToData(results, headers)

  return (
    <>
      <DataGridSortable data={rows} className="matrix-table-analysis">
        {columns.map((column, index) => (
          <GridColumn
            //  @ts-ignore
            cell={renderCell(column.title)}
            field={column.field}
            title={column.title}
            key={index}
            width={index !== 0 && 160}
          />
        ))}
      </DataGridSortable>
      <Divider />
      <AnalysisCardFooter
        options={[ChartType.GRID]}
        currentMode={mode}
        onClick={setMode}
        questionType={type}
        completed={numberOfResponses}
      />
    </>
  )
}

const MatrixAnalysis = props => (
  <BaseAnalysis {...props}>
    <MatrixAnalysisCommonComponent {...props} />
  </BaseAnalysis>
)

export default MatrixAnalysis
