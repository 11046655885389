import { orderBy } from '@progress/kendo-data-query'
import { Grid, GridProps, GridSortSettings } from '@progress/kendo-react-grid'
import React, { useState } from 'react'

const mode: GridSortSettings = { mode: 'multiple' }

const DataGridSortable = (props: { children: React.ReactNode } & GridProps) => {
  const [sort, setSort] = useState([])
  const rawData = props.data as any[]
  const sortChange = event => setSort(event.sort)
  const sortedRawData = orderBy(rawData, sort)

  return (
    <Grid
      style={{
        textAlign: 'right',
        maxHeight: '40vh',
        overflow: 'auto'
      }}
      {...props}
      data={sortedRawData}
      sortable={mode}
      sort={sort}
      onSortChange={sortChange}
    >
      {props.children}
    </Grid>
  )
}

export default DataGridSortable
