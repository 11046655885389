import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

const useBlockManipulation = () => {
  const activeBlockIndex = useSelector(CreatorSelectors.activeBlockIndex)
  const activeBlock = useSelector(CreatorSelectors.activeBlock)
  const blocks = useSelector(CreatorSelectors.blocks)

  const dispatch = useDispatch()

  const setDeltaActiveIndex = (delta: number) =>
    dispatch(
      CreatorActions.setActiveBlock(blocks[activeBlockIndex + delta].blockId)
    )

  const setDeltaBlockOrder = (delta: number) =>
    dispatch(
      CreatorActions.rearrangeBlock(activeBlockIndex, activeBlockIndex + delta)
    )

  const onDuplicateClick = () => {
    const duplicatedBlock = {
      ...cloneDeep(activeBlock),
      blockId: uuidv4()
    }
    const currentBlockLength = blocks.length
    dispatch(CreatorActions.putBlock(duplicatedBlock))
    dispatch(
      CreatorActions.rearrangeBlock(currentBlockLength, activeBlockIndex + 1)
    )
  }

  const onDeleteClick = () =>
    dispatch(CreatorActions.deleteBlock(activeBlock.blockId))

  return {
    onDuplicateClick,
    onDeleteClick,
    setDeltaActiveIndex,
    setDeltaBlockOrder
  }
}

export default useBlockManipulation
