import { WordCloud } from '@ant-design/charts'
import { WordCloudConfig } from '@ant-design/charts/es/plots/wordCloud'
import { GridColumn } from '@progress/kendo-react-grid'
import { GridCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridCellProps'
import { Divider } from 'antd'
import AnalysisCardFooter from 'components/analysis-card-footer'
import DataGridSortable from 'components/data-grid-sortable'
import {
  clickableRowRenderer,
  sparklineRender
} from 'components/survey-creator/question-blocks/analysis-custom-components'
import BaseAnalysis, {
  AnalysisResultsProps
} from 'components/survey-creator/question-blocks/base/analysis'
import { ChartType } from 'components/survey-creator/question-blocks/base/types'
import startCase from 'lodash/startCase'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { fetchCleanWordCloudApi } from 'services/api/analysis'
import { toTableColumns } from 'services/helpers'
import RectangleWordCloudMask from 'static/images/analysis/rectangle-word-cloud-mask.png'

const columns = [
  toTableColumns({ title: 'Answer', field: 'label' }),
  toTableColumns({ title: 'Respondents (%)', field: 'userPercentage' }),
  toTableColumns({ title: 'Respondents', field: 'userCount' })
]

const renderChartValue = (props: GridCellProps) => (
  <td className={'font-mono'}>
    {props.dataItem?.[props.field]?.toFixed(0)}
    {props.field?.toLowerCase()?.includes('percent') && ' %'}
  </td>
)

const TextAnalysis = (props: AnalysisResultsProps) => {
  const { response, onFilterUpdate, filtered } = props
  const [mode, setMode] = useState(ChartType.WORD_CLOUD)
  const { results, numberOfResponses, type } = response

  const { data, isLoading } = useQuery(
    ['clean-text-analysis', results],
    ({ queryKey }) => fetchCleanWordCloudApi(queryKey[1]),
    { enabled: !!results }
  )

  const handleRowClick = e => {
    const answer = e.dataItem.label
    let newValue
    if (filtered.includes(answer)) newValue = filtered.filter(i => i !== answer)
    else newValue = [...filtered, answer]
    onFilterUpdate(response.blockId, newValue)
  }

  const wordCloudConfig: WordCloudConfig = {
    loading: isLoading || !results,
    data: data?.map(i => ({ ...i, label: startCase(i.label) })),
    wordField: 'label',
    weightField: 'userCount',
    // image mask is required to ensure that the word cloud refits on resize
    // https://github.com/ant-design/ant-design-charts/issues/258
    imageMask: RectangleWordCloudMask,
    color: '#122c6a',
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [15, 60]
    }
  }

  const rowRender = (trElement, props) =>
    clickableRowRenderer(trElement, props, filtered)

  const renderCell = title => {
    if (title === 'Index') return sparklineRender
    if (
      title.includes('Answers') ||
      title.includes('Respondents') ||
      title.includes('Count')
    )
      return renderChartValue
    return undefined
  }

  const component = {
    [ChartType.GRID]: (
      <DataGridSortable
        onRowClick={handleRowClick}
        data={results}
        rowRender={rowRender}
      >
        {columns.map((column, index) => (
          <GridColumn
            cell={renderCell(column.title)}
            field={column.field}
            title={column.title}
            key={index}
            width={index !== 0 && 150}
          />
        ))}
      </DataGridSortable>
    ),
    [ChartType.WORD_CLOUD]: <WordCloud {...wordCloudConfig} />
  }

  return (
    <BaseAnalysis {...props}>
      <div>
        {component[mode]}
        <Divider />
        <AnalysisCardFooter
          options={[ChartType.WORD_CLOUD, ChartType.GRID]}
          currentMode={mode}
          onClick={setMode}
          questionType={type}
          completed={numberOfResponses}
        />
      </div>
    </BaseAnalysis>
  )
}

export default TextAnalysis
