import { Button, Result } from 'antd'
import FlexContainer from 'components/flex-container'
import { UserSelectors } from 'data/user'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { sendActivateUserEmailApi } from 'services/api/auth'
import { Color } from 'services/styles'

const ActivatePage = () => {
  const user = useSelector(UserSelectors.user)
  const [isLoading, setIsLoading] = useState(false)
  const [hasSent, setHasSent] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    await sendActivateUserEmailApi(user.id)
    setIsLoading(false)
    setHasSent(true)
  }

  return (
    <FlexContainer
      justifyContent="center"
      style={{
        height: '100%',
        width: '100%',
        position: 'fixed',
        backgroundColor: Color.DIRTY_WHITE,
        zIndex: 1
      }}
    >
      <Result
        title="Your account has not been activated yet"
        subTitle="Please click the link in your inbox to activate your account"
        extra={
          !hasSent ? (
            <Button type="primary" onClick={handleClick} loading={isLoading}>
              Resend email
            </Button>
          ) : (
            <p>Email sent! Please check your inbox for the link</p>
          )
        }
      />
    </FlexContainer>
  )
}

export default ActivatePage
