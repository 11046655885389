enum CreatorConstants {
  PUT_SURVEY = 'CREATOR/PUT_SURVEY',
  PUT_SURVEY_LOADING = 'CREATOR/PUT_SURVEY_LOADING',
  INIT_LOGIC = 'CREATOR/INIT_LOGIC',
  PUT_LOGIC = 'CREATOR/PUT_LOGIC',
  DELETE_LOGIC = 'CREATOR/DELETE_LOGIC',
  INIT_BLOCK = 'CREATOR/INIT_BLOCK',
  PUT_BLOCK = 'CREATOR/PUT_BLOCK',
  DELETE_BLOCK = 'CREATOR/DELETE_BLOCK',
  REARRANGE_BLOCK = 'CREATOR/REARRANGE_BLOCK',
  SET_ACTIVE_BLOCK = 'CREATOR/SET_ACTIVE_BLOCK'
}

export const PUT_SURVEY_THROTTLE = 1500

export default CreatorConstants
