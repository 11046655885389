import { StarFilled } from '@ant-design/icons'
import {
  BaseBlockProps,
  BaseBlockWithAnswers,
  BlockDefinition,
  BlockType,
  InitialBaseBlock,
  Operation,
  QuestionColor
} from '../base/types'

export interface RatingBlockProps extends BaseBlockProps {
  numberOfStars: number
}

const RatingBlockInitialProps: RatingBlockProps = {
  ...InitialBaseBlock,
  type: BlockType.RATING,
  numberOfStars: 5
}

export type RatingAnswerProps = RatingBlockProps & BaseBlockWithAnswers

const RatingBlockDefinition: BlockDefinition = {
  title: 'Rating',
  description: 'For scoring opinion',
  color: QuestionColor[BlockType.RATING],
  icon: StarFilled,
  type: BlockType.RATING,
  initialProps: RatingBlockInitialProps,
  operations: [
    Operation.LOWER_THAN,
    Operation.LOWER_EQUAL_THEN,
    Operation.GREATER_THEN,
    Operation.GREATER_EQUAL_THAN,
    Operation.IN_BETWEEN
  ]
}

export { RatingBlockDefinition }
