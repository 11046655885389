import { TermsOfUse } from 'pages/legal'
import LegalRouteConstants from 'routes/legal/constants'
import { RouteDefinition } from 'routes/types'

const LegalRoutesDefinitions: RouteDefinition[] = [
  {
    path: LegalRouteConstants.TERMS_OF_USE,
    component: TermsOfUse,
    exact: true
  }
]

export default LegalRoutesDefinitions
