import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { Button, Divider } from 'antd'
import FlexContainer from 'components/flex-container'
import { SelectBlockProps } from 'components/survey-creator/question-blocks/sub-components/select-block-common-component/types'
import TextInputClickable from 'components/survey-creator/text-input-clickable'
import { CreatorActions } from 'data/actions'
import cloneDeep from 'lodash/cloneDeep'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { rearrangeArray } from 'services/helpers'
import { Color, Spacing } from 'services/styles'

interface TextChoicesProps {
  choice: string
  index: number
  state: SelectBlockProps
  disabledDelete?: boolean
  disabledRearrange?: boolean
  prefixText?: string
  placeholder?: string
}

const TextChoices: FC<TextChoicesProps> = ({
  choice,
  index,
  state,
  disabledDelete,
  disabledRearrange,
  prefixText,
  placeholder
}) => {
  const dispatch = useDispatch()

  const handleChange = event => {
    const newText = event.target.value
    const newChoice = cloneDeep(state.choices)
    newChoice[index] = newText
    const newReduxState = {
      ...state,
      choices: newChoice
    }
    dispatch(CreatorActions.putBlock(newReduxState))
  }

  const handleDeltaOrder = (oldIndex: number, newIndex: number) => {
    const newReduxState = {
      ...state,
      choices: rearrangeArray(state.choices, oldIndex, newIndex)
    }
    dispatch(CreatorActions.putBlock(newReduxState))
  }

  const handleDelete = () => {
    const newChoice = cloneDeep(state.choices)
    newChoice.splice(index, 1)
    const newReduxState = {
      ...state,
      choices: newChoice
    }
    dispatch(CreatorActions.putBlock(newReduxState))
  }

  const totalChoices = state.choices.length
  const isDownDisabled =
    index === totalChoices - 1 || totalChoices === 1 || disabledRearrange

  const isUpDisabled = index === 0 || totalChoices === 1 || disabledRearrange

  const handleUp = () => !isUpDisabled && handleDeltaOrder(index, index - 1)

  const handleDown = () => !isDownDisabled && handleDeltaOrder(index, index + 1)

  return (
    <FlexContainer style={{ marginLeft: Spacing.TINY / 2 }}>
      <div
        style={{
          marginRight: Spacing.SMALL - Spacing.TINY,
          color: Color.GREY_3,
          textAlign: 'right',
          width: Spacing.SMALL * 1.5
        }}
      >
        {prefixText || (index + 1).toString().padStart(2, '0')}
      </div>
      <TextInputClickable
        autoFocus
        value={choice}
        onChange={handleChange}
        placeholder={placeholder || 'Type next answer'}
      />
      <FlexContainer>
        <Button
          type="text"
          size="small"
          icon={<ArrowDownOutlined />}
          disabled={isDownDisabled}
          onClick={handleDown}
        />
        <Button
          type="text"
          size="small"
          icon={<ArrowUpOutlined />}
          disabled={isUpDisabled}
          onClick={handleUp}
        />
        <Divider type="vertical" />

        {!disabledDelete && (
          <Button
            type="text"
            size="small"
            icon={<CloseOutlined />}
            onClick={handleDelete}
          />
        )}
      </FlexContainer>
    </FlexContainer>
  )
}
export default React.memo(TextChoices)
