import { WorkspaceActions } from 'data/actions'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { listWorkspaceApi } from 'services/api/workspace'
import useCurrentWorkspaceId from 'services/helpers/get-current-workspace'

const useHandleWorkspace = (prefetch = true) => {
  const location = useLocation()
  const history = useHistory()
  const currentWorkspaceId = useCurrentWorkspaceId()

  const { isLoading, data, refetch } = useQuery(
    location.pathname,
    listWorkspaceApi,
    { refetchOnMount: prefetch }
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (data) dispatch(WorkspaceActions.setWorkspaces(data))
  }, [dispatch, data])

  const handleWorkspaceClick = (workspaceId: string) => {
    history.push({ pathname: `/workspace/${workspaceId}` })
    dispatch(WorkspaceActions.setCurrentWorkspace(workspaceId))
  }

  useEffect(() => {
    if (!isLoading && !currentWorkspaceId && data?.[0]?.id)
      handleWorkspaceClick(data?.[0]?.id)
    // eslint-disable-next-line
  }, [currentWorkspaceId, isLoading, data])

  return {
    data,
    isLoading,
    handleWorkspaceClick,
    currentWorkspaceId,
    refetch
  }
}

export default useHandleWorkspace
