import { Rate } from 'antd'
import AnswerPrompt from 'components/survey-creator/question-blocks/answer-prompt'
import React from 'react'

import BaseAnswer from '../base/answer'
import { RatingAnswerProps } from './types'

const RatingAnswerComponent = (props: RatingAnswerProps) => {
  const { numberOfStars, onAnswerUpdate } = props

  const handleStarChange = stars => onAnswerUpdate(stars)

  return (
    <div className="flex flex-col justify-center flex-1 w-full align-center rating-answer">
      <AnswerPrompt text={'TAP BUTTON TO RATE'} />
      <Rate
        count={numberOfStars}
        onChange={handleStarChange}
        style={{ color: '#fdbe4a', alignSelf: 'center' }}
      />
    </div>
  )
}

const RatingAnswer = props => {
  return <BaseAnswer {...props} component={RatingAnswerComponent} />
}

export default RatingAnswer
