import { BiSlider } from 'react-icons/all'
import {
  BaseBlockProps,
  BaseBlockWithAnswers,
  BlockDefinition,
  BlockType,
  InitialBaseBlock,
  Operation,
  QuestionColor
} from '../base/types'

export interface SliderBlockProps extends BaseBlockProps {
  choices: string[]
  range: { min: number; max: number; step: number }
}

const SliderInitialProps: SliderBlockProps = {
  ...InitialBaseBlock,
  type: BlockType.SLIDER,
  choices: ['', ''],
  range: { min: 0, max: 10, step: 1 }
}

export type SliderAnswerProps = SliderBlockProps & BaseBlockWithAnswers

const SliderBlockDefinition: BlockDefinition = {
  title: 'Slider',
  description: 'Numbered scale',
  color: QuestionColor[BlockType.SLIDER],
  icon: BiSlider,
  type: BlockType.SLIDER,
  initialProps: SliderInitialProps,
  operations: [
    Operation.LOWER_THAN,
    Operation.LOWER_EQUAL_THEN,
    Operation.GREATER_THEN,
    Operation.GREATER_EQUAL_THAN,
    Operation.IN_BETWEEN
  ]
}

export { SliderBlockDefinition }
