import { CreatorAudience, CreatorEditor, CreatorSummary } from 'pages/creator'
import CreatorRouteConstants from 'routes/creator/constants'
import { RouteDefinition } from 'routes/types'

const CreatorRoutesDefinitions: RouteDefinition[] = [
  {
    path: CreatorRouteConstants.CREATOR_AUDIENCE,
    component: CreatorAudience,
    exact: false
  },
  {
    path: CreatorRouteConstants.CREATOR_EDITOR,
    component: CreatorEditor,
    exact: false
  },
  {
    path: CreatorRouteConstants.CREATOR_SUMMARY,
    component: CreatorSummary,
    exact: false
  }
]
export default CreatorRoutesDefinitions
