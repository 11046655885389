import {
  BaseBlockProps,
  InitialBaseBlock
} from 'components/survey-creator/question-blocks/base/types'

export interface SelectBlockProps extends BaseBlockProps {
  choices: string[]
  randomize: boolean
  noneOfTheAbove: boolean
  other: boolean
}

export const InitialSelectBlockProps = {
  ...InitialBaseBlock,
  choices: [],
  randomize: false,
  noneOfTheAbove: false,
  other: false
}

export type SelectChoices = string[]
