import config from 'config'
import { initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth'

initializeApp(config.firebaseConfig)

const auth = getAuth()

new GoogleAuthProvider()

const firebaseLogin = async (email: string, password: string) => {
  await setPersistence(auth, browserLocalPersistence)
  return signInWithEmailAndPassword(auth, email, password)
}
const googleSignInWithPopup = async () => {}

const firebaseRegister = async (email: string, password: string) => {
  await setPersistence(auth, browserLocalPersistence)
  return createUserWithEmailAndPassword(auth, email, password)
}

const firebaseLogout = async () => signOut(auth)

const firebaseGetCurrentUser = () => auth.currentUser

const firebaseForceRefresh = () => auth?.currentUser?.getIdToken(true)

interface AuthStateObserverProps {
  handleFirebaseReady: () => void
  handleFirebaseAuthStateChange: () => void
}

let isFirebaseReady = false

const firebaseAuthStateObserver = ({
  handleFirebaseReady,
  handleFirebaseAuthStateChange
}: AuthStateObserverProps) => {
  onAuthStateChanged(auth, _ => {
    handleFirebaseAuthStateChange()
    if (!isFirebaseReady) {
      handleFirebaseReady()
      isFirebaseReady = true
    }
  })
}

export {
  firebaseAuthStateObserver,
  firebaseGetCurrentUser,
  firebaseLogin,
  firebaseRegister,
  firebaseLogout,
  firebaseForceRefresh,
  googleSignInWithPopup
}
