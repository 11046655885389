import { Button, Col, Form, Input, Row, Select, Typography } from 'antd'

import { UserActions } from 'data/user'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { getUserApi, registerApi } from 'services/api/auth'
import {
  firebaseForceRefresh,
  firebaseRegister
} from 'services/integrations/firebase'
import { Spacing } from 'services/styles'
import UAskLogo from 'static/logo.png'
import AuthBase from '../auth-base'
import companySize from './company-size.json'
import countryList from './country-list.json'
import jobTitles from './job-titles.json'

const { Title, Text } = Typography
const { Option } = Select
const RegisterPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const dispatch = useDispatch()

  const handleRegister = async props => {
    const { email, password } = props
    await firebaseRegister(email, password)
    await registerApi({ ...props, password: undefined })
    await firebaseForceRefresh()
    const user = await getUserApi()

    dispatch(UserActions.setUser(user))
  }

  const onFinish = async (props: any) => {
    setIsLoading(true)
    setHasError(false)
    try {
      await handleRegister(props)
    } catch (e) {
      setHasError(true)
      console.log(e.message)
    }
    setIsLoading(false)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <AuthBase>
      <Row style={{ width: '100%', maxWidth: 600 }}>
        <Col span={18} offset={3}>
          <img
            src={UAskLogo}
            style={{ height: 60, marginBottom: Spacing.MEDIUM }}
            alt={'uask-logo'}
          />
          <Title level={2} style={{ marginBottom: 0 }}>
            Register
          </Title>
          <Title
            level={5}
            style={{
              marginTop: Spacing.TINY / 2,
              marginBottom: Spacing.SMALL * 1.5,
              fontWeight: 400
            }}
          >
            have an account? <Link to={'/login'}>login here</Link>
          </Title>
          <Form
            layout="vertical"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              hasFeedback
              label="Email"
              name="email"
              rules={[{ message: 'Please input your email', required: true }]}
            >
              <Input size={'large'} disabled={isLoading} />
            </Form.Item>
            <Row gutter={Spacing.SMALL}>
              <Col span={12}>
                <Form.Item
                  label="First Name"
                  name="firstName"
                  hasFeedback
                  rules={[
                    { required: true, message: 'Please input your first name' }
                  ]}
                >
                  <Input size={'large'} disabled={isLoading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Last Name"
                  name="lastName"
                  hasFeedback
                  rules={[
                    { required: true, message: 'Please input your last name' }
                  ]}
                >
                  <Input size={'large'} disabled={isLoading} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="country"
              label="Country"
              hasFeedback
              rules={[
                { required: true, message: 'Please select your country' }
              ]}
            >
              <Select
                placeholder="Country"
                size={'large'}
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {countryList.map(({ country }) => (
                  <Option value={country} key={country}>
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Row gutter={Spacing.SMALL}>
              <Col span={12}>
                <Form.Item
                  hasFeedback
                  label="Company Name"
                  name="companyName"
                  rules={[
                    {
                      message: 'Please input your company name',
                      required: true
                    }
                  ]}
                >
                  <Input size={'large'} disabled={isLoading} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="companySize"
                  label="Company Size"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please select your company size'
                    }
                  ]}
                >
                  <Select
                    placeholder="Company Size"
                    size={'large'}
                    optionFilterProp="children"
                    showSearch
                    filterOption={(input, option) =>
                      option?.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {companySize.map(({ size }) => (
                      <Option value={size} key={size}>
                        {size}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="jobTitle"
              label="Job Title"
              hasFeedback
              rules={[
                { required: true, message: 'Please select your job title' }
              ]}
            >
              <Select
                placeholder="Job Title"
                size={'large'}
                optionFilterProp="children"
                showSearch
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {jobTitles.map(({ jobTitle }) => (
                  <Option value={jobTitle} key={jobTitle}>
                    {jobTitle}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { message: 'Please input your password', required: true }
              ]}
            >
              <Input.Password size={'large'} disabled={isLoading} />
            </Form.Item>
            {hasError && (
              <div
                style={{
                  marginBottom: Spacing.SMALL,
                  marginTop: Spacing.SMALL
                }}
              >
                <Text type="danger">
                  The username/password combination is invalid.
                </Text>
              </div>
            )}
            <h4 className={'mb-3'}>
              By clicking register, you agree to our{' '}
              <Link
                to={'/terms-of-use'}
                target="_blank"
                style={{ color: '#1890ff' }}
              >
                Terms of Use
              </Link>
            </h4>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                size={'large'}
                loading={isLoading}
              >
                Register
              </Button>
            </Form.Item>
          </Form>
          {/*<Divider>OR</Divider>*/}
          {/*<Button*/}
          {/*  disabled*/}
          {/*  type="ghost"*/}
          {/*  block*/}
          {/*  size={'large'}*/}
          {/*  onClick={googleSignInWithPopup}*/}
          {/*  icon={*/}
          {/*    <img*/}
          {/*      src="https://img.icons8.com/color/25/000000/google-logo.png"*/}
          {/*      style={{ marginRight: Spacing.SMALL }}*/}
          {/*      alt="google-icon"*/}
          {/*    />*/}
          {/*  }*/}
          {/*>*/}
          {/*  Sign in with Google*/}
          {/*</Button>*/}
        </Col>
      </Row>
    </AuthBase>
  )
}

export default RegisterPage
