import { InputNumber, Space } from 'antd'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import BaseLogicComponent from '../base/logic'
import { useHandleFieldUpdate } from '../base/logic/hooks'
import { BaseLogicSubComponentProps, Operation } from '../base/types'
import { SliderBlockProps } from './types'

const SliderLogicSubcomponent = ({
  logicId,
  action,
  actionGroupId
}: BaseLogicSubComponentProps) => {
  const activeBlock = useSelector(
    CreatorSelectors.activeBlock
  ) as SliderBlockProps

  const handleFieldUpdate = useHandleFieldUpdate(logicId, actionGroupId, action)

  const handleSingleChange = value => handleFieldUpdate({ value })

  const handleStartChange = start => handleFieldUpdate({ start })

  const handleEndChange = end => handleFieldUpdate({ end })

  if (action?.condition?.operation === Operation.IN_BETWEEN) {
    return (
      <Space>
        <InputNumber
          min={activeBlock.range.min}
          max={activeBlock.range.max}
          value={action.condition?.field?.start}
          onChange={handleStartChange}
        />
        and
        <InputNumber
          min={activeBlock.range.min}
          max={activeBlock.range.max}
          value={action.condition?.field?.end}
          onChange={handleEndChange}
        />
      </Space>
    )
  }

  return (
    <>
      <InputNumber
        value={action.condition?.field?.value}
        onChange={handleSingleChange}
      />
    </>
  )
}

const SliderLogic = () => (
  <BaseLogicComponent component={SliderLogicSubcomponent} />
)

export default SliderLogic
