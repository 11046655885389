import { UploadOutlined } from '@ant-design/icons'
import { Button, Switch, Upload } from 'antd'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import FlexContainer from 'components/flex-container'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadImageApi } from 'services/api/common/misc'
import { useHover } from 'services/hooks'
import { Spacing } from 'services/styles'
import { HoverWrapper } from './style'

const { Dragger } = Upload

interface UploadComponentProps {
  onImageChange: (newUrl: string) => void
  imageUrl: string
}

const CompleteImageRender = ({ imageUrl, onDelete }) => {
  const ref = useRef(null)
  const isHover = useHover(ref)
  return (
    <div style={{ position: 'relative' }} ref={ref}>
      <img src={imageUrl} style={{ width: '100%' }} alt={'complete'} />
      <HoverWrapper>
        {isHover && (
          <FlexContainer
            style={{ height: '100%', width: '100%' }}
            justifyContent="center"
          >
            <Button type="primary" onClick={onDelete}>
              Delete
            </Button>
          </FlexContainer>
        )}
      </HoverWrapper>
    </div>
  )
}

const UploadComponent = ({ onImageChange, imageUrl }: UploadComponentProps) => {
  const [url, setUrl] = useState('')
  const [headers, setHeaders] = useState({})
  const [data, setData] = useState<any>({})

  const beforeUpload = async file => {
    const data = (await uploadImageApi(file.type)) as any
    setUrl(data.url)
    setData(data.fields)
    setHeaders(data.fields)
    file.url = data.url + '/' + data.fields.key
    return file
  }

  const getData = async file => ({
    ...data,
    acl: 'public-read',
    'Content-Type': file.type
  })

  const onChange = (change: UploadChangeParam) => {
    if (change.file.status === 'done') onImageChange(change.file.url)
  }

  const onDelete = () => onImageChange(null)

  if (imageUrl) {
    return <CompleteImageRender imageUrl={imageUrl} onDelete={onDelete} />
  }

  return (
    <Dragger
      accept="image/*"
      headers={headers}
      action={url}
      maxCount={1}
      beforeUpload={beforeUpload}
      data={getData}
      name={'file'}
      onChange={onChange}
    >
      <UploadOutlined />
      <div>Upload</div>
    </Dragger>
  )
}

const MediaComponent = () => {
  const activeBlock = useSelector(CreatorSelectors.activeBlock)

  const [isOpen, setIsOpen] = useState(!!activeBlock.media)
  const [imageUrl, setImageUrl] = useState<string>(activeBlock.media)
  const dispatch = useDispatch()

  const onChange = (media: string | null) => {
    dispatch(
      CreatorActions.putBlock({
        ...activeBlock,
        media
      })
    )
    setImageUrl(media)
  }

  return (
    <div>
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <p style={{ marginBottom: 0 }}>Add media to question</p>
        <Switch
          checked={isOpen}
          onChange={_ => setIsOpen(!isOpen)}
          size="small"
        />
      </FlexContainer>
      {isOpen && (
        <UploadComponent onImageChange={onChange} imageUrl={imageUrl} />
      )}
    </div>
  )
}
export default MediaComponent
