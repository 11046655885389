import { Layout } from 'antd'
import HeaderComponent from 'components/header-component'
import React from 'react'
import { Color } from 'services/styles'

const { Content } = Layout

const RootComponent = ({ children }) => (
  <Layout style={{ height: '100%' }}>
    <HeaderComponent />
    <Content style={{ backgroundColor: Color.WHITE }}>{children}</Content>
  </Layout>
)

export default RootComponent
