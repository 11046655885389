import { Input } from 'antd'
import React from 'react'
import BaseLogicComponent from '../base/logic'
import { useHandleFieldUpdate } from '../base/logic/hooks'
import { BaseLogicSubComponentProps } from '../base/types'

const TextLogicSubcomponent = ({
  logicId,
  action,
  actionGroupId
}: BaseLogicSubComponentProps) => {
  const handleUpdate = useHandleFieldUpdate(logicId, actionGroupId, action)

  const handleChange = e => {
    const value = e.target.value
    handleUpdate({ value })
  }

  return (
    <>
      <Input
        autoFocus
        value={action.condition?.field?.value}
        onChange={handleChange}
      />
    </>
  )
}

const TextLogic = () => <BaseLogicComponent component={TextLogicSubcomponent} />

export default TextLogic
