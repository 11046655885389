import UserConstants from 'data/user/constants'
import handleActionsImmer from 'services/integrations/immer'
import { UserState } from './types'

const user = handleActionsImmer<UserState>(
  {
    [UserConstants.IS_FETCHING_USER]: (state, { isFetchingUser }) => {
      state.isFetchingUser = isFetchingUser
    },
    [UserConstants.SET_USER]: (state, { user }) => {
      state.user = user
    },
    [UserConstants.FIREBASE_SET_READY]: state => {
      state.isFirebaseReady = true
    }
  },
  { user: null, isFirebaseReady: false, isFetchingUser: false }
)

export default user
