import handleActionsImmer from 'services/integrations/immer'
import WorkspaceConstants from './constants'
import { WorkspaceState } from './types'

const workspaceReducer = handleActionsImmer<WorkspaceState>(
  {
    [WorkspaceConstants.PUT_WORKSPACES]: (state, { workspaces }) => {
      state.workspaces = workspaces
    },
    [WorkspaceConstants.SET_WORKSPACE]: (state, { workspaceId }) => {
      state.selectedWorkspace = workspaceId
    }
  },
  { workspaces: [], selectedWorkspace: null }
)

export default workspaceReducer
