import axios from 'axios'
import getApiRoute from 'services/api/common/get-api-route'
import { SurveyStatus } from 'types/survey'

export const getSurveyById = async id => {
  const { data } = await axios.get(getApiRoute(`/project/${id}`))
  return data
}

export const postSurveyApi = async (workspaceId, props) => {
  const { data } = await axios.post(
    getApiRoute(`/project/${workspaceId}`),
    props
  )
  return data
}

export const putSurveyApi = async (surveyId, props) => {
  const { data } = await axios.put(getApiRoute(`/project/${surveyId}`), props)
  return data
}

export const listSurveyApi = async (
  status: SurveyStatus,
  workspace: string
) => {
  const { data } = await axios.get(
    getApiRoute(
      `/project?filter=${status.toLowerCase()}&workspace=${workspace}`
    )
  )
  return data
}

export const deleteSurveyApi = async id => {
  const { data } = await axios.delete(getApiRoute(`/project/${id}`))
  return data
}

const searchSurveyApiCancelToken = axios.CancelToken
let cancel

export const searchSurveyApi = async q => {
  if (q?.length < 1) return []
  try {
    cancel && cancel()
    const { data } = await axios.get(getApiRoute(`/project/search?q=${q}`), {
      cancelToken: new searchSurveyApiCancelToken(c => (cancel = c))
    })
    return data
  } catch (e) {
    if (axios.isCancel(e)) return null
    return []
  }
}
