import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { Button, Divider } from 'antd'
import TextInputClickable from 'components/survey-creator/text-input-clickable'
import { CreatorActions } from 'data/creator'
import cloneDeep from 'lodash/cloneDeep'
import React from 'react'
import { useDispatch } from 'react-redux'
import { rearrangeArray } from 'services/helpers'

const MatrixRow = ({ state, index, disabledRearrange, value, onRowChange }) => {
  const dispatch = useDispatch()

  const handleDeltaOrder = (oldIndex: number, newIndex: number) => {
    dispatch(
      CreatorActions.putBlock({
        ...state,
        choices: rearrangeArray(state.choices, oldIndex, newIndex)
      })
    )
  }

  const handleDelete = () => {
    const newChoice = cloneDeep(state.choices)
    newChoice.splice(index, 1)
    dispatch(CreatorActions.putBlock({ ...state, choices: newChoice }))
  }

  const totalChoices = state.choices.length
  const isDownDisabled =
    index === totalChoices - 1 || totalChoices === 1 || disabledRearrange

  const isUpDisabled = index === 0 || totalChoices === 1 || disabledRearrange

  const handleUp = () => !isUpDisabled && handleDeltaOrder(index, index - 1)

  const handleDown = () => !isDownDisabled && handleDeltaOrder(index, index + 1)

  return (
    <div className="flex flex-row items-center">
      <TextInputClickable value={value} onChange={onRowChange} />
      <Button
        type="text"
        size="small"
        icon={<ArrowDownOutlined />}
        disabled={isDownDisabled}
        onClick={handleDown}
      />
      <Button
        type="text"
        size="small"
        icon={<ArrowUpOutlined />}
        disabled={isUpDisabled}
        onClick={handleUp}
      />
      <Divider type="vertical" />

      <Button
        type="text"
        size="small"
        icon={<CloseOutlined />}
        onClick={handleDelete}
      />
    </div>
  )
}
export default MatrixRow
