import {
  ActivatePage,
  ConfirmPage,
  ForgotPasswordPage,
  LoginPage,
  RegisterPage,
  ResetPasswordPage
} from 'pages/auth'
import AuthRouteConstants from 'routes/auth/constants'
import { RouteDefinition } from 'routes/types'

const AuthRouteDefinitions: RouteDefinition[] = [
  {
    path: AuthRouteConstants.LOGIN,
    component: LoginPage,
    exact: true
  },
  {
    path: AuthRouteConstants.REGISTER,
    component: RegisterPage,
    exact: true
  },
  {
    path: AuthRouteConstants.ACTIVATE,
    component: ActivatePage,
    exact: true
  },
  {
    path: AuthRouteConstants.CONFIRM_ACCOUNT,
    component: ConfirmPage,
    exact: true
  },
  {
    path: AuthRouteConstants.RESET_PASSWORD,
    component: ResetPasswordPage,
    exact: true
  },
  {
    path: AuthRouteConstants.FORGOT_PASSWORD,
    component: ForgotPasswordPage,
    exact: true
  }
]

export default AuthRouteDefinitions
