import { SelectBlockProps } from 'components/survey-creator/question-blocks/sub-components/select-block-common-component/types'
import TextInputClickable from 'components/survey-creator/text-input-clickable'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { useParamSelector } from 'services/hooks'
import { Spacing } from 'services/styles'
import TextChoices from '../text-choices'

const SelectBlockComponent: FC<SelectBlockProps> = props => {
  const dispatch = useDispatch()

  const blockState = useParamSelector(
    CreatorSelectors.getBlockById,
    props.blockId
  )

  const onEnterNewLine = props => {
    const newReduxState = {
      ...blockState,
      choices: [...blockState.choices, props.target.value]
    }
    dispatch(CreatorActions.putBlock(newReduxState))
  }

  return (
    <>
      {blockState.choices.map((choice, index) => (
        <div key={`${index}`}>
          <TextChoices state={blockState} choice={choice} index={index} />
        </div>
      ))}
      <div style={{ marginLeft: Spacing.MEDIUM }}>
        <TextInputClickable
          value={''}
          onChange={onEnterNewLine}
          placeholder="Type an answer"
        />
      </div>
    </>
  )
}

export default SelectBlockComponent
