import { PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu } from 'antd'
import FlexContainer from 'components/flex-container'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BorderRadius, Spacing } from 'services/styles'
import { v4 as uuidv4 } from 'uuid'
import { BlocksDefinition } from '../question-blocks'

const AddNewMenu = ({ index }) => {
  const blocksLength = useSelector(CreatorSelectors.blocksLength)
  const dispatch = useDispatch()

  const handleMenuClick = initialProps => {
    dispatch(CreatorActions.putBlock({ ...initialProps, blockId: uuidv4() }))
    dispatch(CreatorActions.rearrangeBlock(blocksLength, index + 1))
  }
  const menu = (
    <Menu style={{ minWidth: 250 }}>
      {Object.values(BlocksDefinition).map(question => {
        const {
          icon: Icon,
          color,
          title,
          initialProps
        } = question.blockDefinition
        return (
          <Menu.Item
            key={title}
            onClick={_ => handleMenuClick(initialProps)}
            style={{}}
          >
            <FlexContainer justifyContent={'flex-start'}>
              <FlexContainer
                justifyContent={'center'}
                style={{
                  backgroundColor: color,
                  marginRight: Spacing.SMALL,
                  padding: Spacing.TINY / 2,
                  borderRadius: BorderRadius.TINY
                }}
              >
                <Icon style={{ fontSize: 10 }} />
              </FlexContainer>
              <p style={{ marginBottom: 0, fontSize: 14, fontWeight: 600 }}>
                {title}
              </p>
            </FlexContainer>
          </Menu.Item>
        )
      })}
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
      <Button type="primary" icon={<PlusOutlined />}>
        Add new
      </Button>
    </Dropdown>
  )
}

export default React.memo(AddNewMenu)
