import React from 'react'
import BaseBlock from '../base/block'
import SelectBlock from '../sub-components/select-block-common-component'

const SingleSelectBlock = props => (
  <BaseBlock {...props}>
    <SelectBlock {...props} />
  </BaseBlock>
)

export default SingleSelectBlock
