import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import produce from 'immer'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import { ActionOperation, LogicOperation } from '../types'

export const useHandleLogicChange = () => {
  const activeLogics = useSelector(CreatorSelectors.activeLogics)
  const activeBlockId = useSelector(CreatorSelectors.activeBlockId)
  const activeBlock = useSelector(CreatorSelectors.activeBlock)

  useEffect(() => {
    for (const eachActiveLogic of activeLogics) {
      if (eachActiveLogic.actionGroups.length === 0) {
        dispatch(CreatorActions.deleteLogic(eachActiveLogic.logicId))
      }
    }
    // eslint-disable-next-line
  }, [activeLogics])

  const dispatch = useDispatch()

  const handleToChange = (value, logicId: string) => {
    dispatch(
      CreatorActions.putLogic({
        logicId,
        to: { ref: value }
      })
    )
  }

  const handleAddNewActionGroup = (logicId: string) => {
    const activeLogic = activeLogics.find(i => i.logicId === logicId)
    if (activeLogic) {
      const actionGroups = produce(activeLogic, draft => {
        const actionOperation = activeLogic.actionGroups?.[0].actionOperation
        draft.actionGroups.push({
          actionOperation,
          actionGroupId: uuidv4(),
          actions: [{ actionId: uuidv4() }]
        })
        return draft
      }).actionGroups

      dispatch(
        CreatorActions.putLogic({
          logicId: activeLogic.logicId,
          actionGroups
        })
      )
    } else {
      dispatch(
        CreatorActions.putLogic({
          logicId: uuidv4(),
          ref: activeBlockId,
          logicOperation: LogicOperation.AND,
          type: activeBlock.type,
          actionGroups: [
            {
              actionOperation: ActionOperation.AND,
              actionGroupId: uuidv4(),
              actions: [{ actionId: uuidv4() }]
            }
          ]
        })
      )
    }
  }

  const handleDeleteLogic = logicId =>
    dispatch(CreatorActions.deleteLogic(logicId))

  const handleBaseOperationChange = (logicId: string, logicOperation) =>
    dispatch(
      CreatorActions.putLogic({
        logicId,
        logicOperation
      })
    )

  const handleActionEqualityChange = (
    logicId: string,
    operation,
    actionGroupId: string,
    actionId: string
  ) => {
    const activeLogic = activeLogics.find(i => i.logicId === logicId)
    const actionGroups = produce(activeLogic, draft => {
      const actionGroupIndex = draft.actionGroups.findIndex(
        i => i.actionGroupId === actionGroupId
      )
      const actionIndex = draft.actionGroups[
        actionGroupIndex
      ].actions.findIndex(i => i.actionId === actionId)
      draft.actionGroups[actionGroupIndex].actions[actionIndex].condition = {
        ...draft.actionGroups[actionGroupIndex].actions[actionIndex].condition,
        operation
      }
      return draft
    }).actionGroups

    dispatch(
      CreatorActions.putLogic({
        logicId,
        actionGroups
      })
    )
  }

  const handleActionOperationChange = (
    logicId: string,
    operation: ActionOperation,
    actionGroupId: string
  ) => {
    const activeLogic = activeLogics.find(i => i.logicId === logicId)
    const actionGroups = produce(activeLogic, draft => {
      const actionGroupIndex = draft.actionGroups.findIndex(
        i => i.actionGroupId === actionGroupId
      )
      draft.actionGroups[actionGroupIndex].actionOperation = operation
      return draft
    }).actionGroups

    dispatch(
      CreatorActions.putLogic({
        logicId: activeLogic.logicId,
        actionGroups
      })
    )
  }

  const handleAddLogicToActionGroup = (
    logicId: string,
    actionGroupId: string
  ) => {
    const activeLogic = activeLogics.find(i => i.logicId === logicId)

    const actionGroups = produce(activeLogic, draft => {
      const actionGroupIndex = draft.actionGroups.findIndex(
        i => i.actionGroupId === actionGroupId
      )
      draft.actionGroups[actionGroupIndex].actions.push({
        actionId: uuidv4()
      })
      return draft
    }).actionGroups

    dispatch(
      CreatorActions.putLogic({
        logicId: activeLogic.logicId,
        actionGroups
      })
    )
  }

  const handleDeleteLogicFromAction = (
    logicId: string,
    actionGroupId: string,
    actionId: string
  ) => {
    const activeLogic = activeLogics.find(i => i.logicId === logicId)
    const actionGroupIndex = activeLogic.actionGroups.findIndex(
      i => i.actionGroupId === actionGroupId
    )

    const actionGroups = produce(activeLogic, draft => {
      if (activeLogic.actionGroups[actionGroupIndex].actions.length === 1) {
        draft.actionGroups = draft.actionGroups.filter(
          i => i.actionGroupId !== actionGroupId
        )
      } else {
        draft.actionGroups[actionGroupIndex].actions = draft.actionGroups[
          actionGroupIndex
        ].actions.filter(i => i.actionId !== actionId)
      }
      return draft
    }).actionGroups

    dispatch(
      CreatorActions.putLogic({
        logicId: activeLogic.logicId,
        actionGroups
      })
    )
  }

  return {
    handleToChange,
    handleAddNewActionGroup,
    handleBaseOperationChange,
    handleActionEqualityChange,
    handleActionOperationChange,
    handleAddLogicToActionGroup,
    handleDeleteLogicFromAction,
    handleDeleteLogic
  }
}

export const useHandleFieldUpdate = (
  logicId: string,
  actionGroupId: string,
  action
) => {
  const dispatch = useDispatch()

  const activeLogics = useSelector(CreatorSelectors.activeLogics)
  const activeLogic = activeLogics.find(i => i.logicId === logicId)

  const actionId = action.actionId

  const _handleUpdate = (props: any) => {
    const actionGroups = produce(activeLogic, draft => {
      const actionGroupIndex = draft.actionGroups.findIndex(
        i => i.actionGroupId === actionGroupId
      )
      const actionIndex = draft.actionGroups[
        actionGroupIndex
      ].actions.findIndex(i => i.actionId === actionId)

      draft.actionGroups[actionGroupIndex].actions[
        actionIndex
      ].condition.field = {
        ...draft.actionGroups[actionGroupIndex].actions[actionIndex]?.condition
          ?.field,
        ...props
      }

      return draft
    }).actionGroups

    dispatch(
      CreatorActions.putLogic({
        logicId: activeLogic.logicId,
        actionGroups
      })
    )
  }
  return _handleUpdate
}
