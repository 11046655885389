import FlexContainer from 'components/flex-container'
import shuffle from 'lodash/shuffle'
import React, { useEffect, useState } from 'react'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult
} from 'react-beautiful-dnd'
import { rearrangeArray } from 'services/helpers'
import AnswerPrompt from '../answer-prompt'
import BaseAnswer from '../base/answer'
import RankingAnswerDraggable from './ranking-answer-draggable'
import { RankingAnswerProps } from './types'

const RankingAnswerComponent = (props: RankingAnswerProps) => {
  const { choices, onAnswerUpdate, randomize } = props
  const [choicesInOrder, setChoicesInOrder] = useState<string[]>([])

  useEffect(() => {
    if (randomize) setChoicesInOrder(shuffle(choices))
    else setChoicesInOrder(choices)
  }, [choices, randomize])

  useEffect(() => {
    onAnswerUpdate(choicesInOrder)
    // eslint-disable-next-line
  }, [choicesInOrder])

  const onDragEnd = ({ source, destination }: DropResult) =>
    onArrangeBlocks(source?.index, destination?.index)

  const onArrangeBlocks = (index, toIndex) => {
    const rearrangedArray = rearrangeArray(choicesInOrder, index, toIndex)
    setChoicesInOrder(rearrangedArray)
  }

  return (
    <FlexContainer
      justifyContent={'center'}
      style={{ width: '100%' }}
      flexDirection={'column'}
    >
      <AnswerPrompt text={'RANK BY DRAGGING OR SELECTING'} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="answer-ranking-list">
          {provided => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              style={{ width: '100%' }}
            >
              {choicesInOrder.map((choice, index) => {
                return (
                  <Draggable
                    draggableId={choice}
                    index={index}
                    key={`${index}-${choice}`}
                  >
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <RankingAnswerDraggable
                          choices={choicesInOrder}
                          index={index}
                          text={choice}
                          onChange={onArrangeBlocks}
                        />
                      </div>
                    )}
                  </Draggable>
                )
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </FlexContainer>
  )
}
const RankingAnswer = props => (
  <BaseAnswer {...props} single component={RankingAnswerComponent} />
)

export default RankingAnswer
