import { Select } from 'antd'
import { useHandleFieldUpdate } from 'components/survey-creator/question-blocks/base/logic/hooks'
import { BaseLogicSubComponentProps } from 'components/survey-creator/question-blocks/base/types'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import { SelectBlockProps } from '../select-block-common-component/types'

const SelectBlockLogicComponent = ({
  logicId,
  action,
  actionGroupId
}: BaseLogicSubComponentProps) => {
  const activeBlock = useSelector(
    CreatorSelectors.activeBlock
  ) as SelectBlockProps

  const handleFieldUpdate = useHandleFieldUpdate(logicId, actionGroupId, action)

  const handleValueChange = value => handleFieldUpdate({ value })

  return (
    <>
      <Select
        style={{ minWidth: 140, width: '100%' }}
        value={action?.condition?.field?.value}
        onChange={handleValueChange}
      >
        {activeBlock.choices.map((i, index) => (
          <Select.Option value={i} key={i}>
            {i}
          </Select.Option>
        ))}
      </Select>
    </>
  )
}
export default React.memo(SelectBlockLogicComponent)
