import { Spin } from 'antd'
import React from 'react'

interface FillLoaderProps {
  text?: string
}

const FillLoader = ({ text = 'Loading...' }: FillLoaderProps) => (
  <div className="flex flex-col items-center justify-center h-full w-full">
    <Spin size="large" className="mb-3" />
    {text}
  </div>
)

export default FillLoader
