import { InputProps } from 'antd/lib/input/Input'
import React, { FC } from 'react'
import { enterToTab } from 'services/helpers'
import { Input, InputWrapper } from './style'

interface TextInputClickableProps extends InputProps {
  containerStyle?: any
  inputStyle?: any
}

const TextInputClickable: FC<TextInputClickableProps> = props => {
  const otherProps = {
    ...props,
    inputStyle: undefined,
    containerStyle: undefined
  }

  return (
    <InputWrapper style={props.containerStyle}>
      <Input
        style={props.inputStyle}
        onPressEnter={enterToTab}
        {...otherProps}
        bordered={false}
      />
    </InputWrapper>
  )
}

export default React.memo(TextInputClickable)
