import {
  BarChartOutlined,
  CloudOutlined,
  PieChartOutlined,
  TableOutlined
} from '@ant-design/icons'
import { Button, Space, Tag } from 'antd'
import FlexContainer from 'components/flex-container'
import {
  BlockType,
  ChartType
} from 'components/survey-creator/question-blocks/base/types'
import startCase from 'lodash/startCase'
import React from 'react'

const chartTypeToIcon = {
  [ChartType.COLUMN]: BarChartOutlined,
  [ChartType.PIE]: PieChartOutlined,
  [ChartType.GRID]: TableOutlined,
  [ChartType.WORD_CLOUD]: CloudOutlined
}

interface AnalysisCardFooterProps {
  options: ChartType[]
  currentMode: any
  onClick: (type: ChartType) => void
  completed: number
  questionType: BlockType
}

const AnalysisCardFooter = ({
  options,
  currentMode,
  onClick,
  completed,
  questionType
}: AnalysisCardFooterProps) => (
  <FlexContainer>
    <Space>
      {options.map(type => {
        const Icon = chartTypeToIcon[type]
        return (
          <Button
            key={type}
            icon={<Icon />}
            type={currentMode === type ? 'primary' : 'ghost'}
            onClick={() => onClick(type)}
          />
        )
      })}
    </Space>
    <Space>
      <Tag>{startCase(questionType)}</Tag>
      {/*  Completes: {completed}*/}
    </Space>
  </FlexContainer>
)

export default AnalysisCardFooter
