import { Button, Col, Form, Input, Row, Typography } from 'antd'
import AuthBase from 'pages/auth/auth-base'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { forgotPasswordApi } from 'services/api/auth'
import { Spacing } from 'services/styles'
import UAskLogo from 'static/logo.png'

const { Title, Text } = Typography

const ForgotPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [success, setSuccess] = useState(false)
  const history = useHistory()

  const onFinish = async ({ username }: any) => {
    setIsLoading(true)
    setHasError(false)
    try {
      await forgotPasswordApi(username)
      setSuccess(true)
    } catch (e) {
      setHasError(true)
      setIsLoading(false)
      console.log(e.message)
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <AuthBase>
      <Row style={{ width: '100%', maxWidth: 600 }}>
        <Col span={18} offset={3}>
          <img
            src={UAskLogo}
            style={{ height: 60, marginBottom: Spacing.MEDIUM }}
            alt={'uask-logo'}
          />
          <Title level={2} style={{ marginBottom: 0 }}>
            Forgot password
          </Title>
          <Title
            level={5}
            style={{
              marginTop: Spacing.TINY / 2,
              marginBottom: Spacing.SMALL * 1.5,
              fontWeight: 400
            }}
          >
            To reset your password, please enter your email
          </Title>

          {!success ? (
            <Form
              layout="vertical"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { message: 'Please input your username!', required: true }
                ]}
              >
                <Input
                  size={'large'}
                  disabled={isLoading}
                  placeholder={'Username'}
                />
              </Form.Item>

              {hasError && (
                <div
                  style={{
                    marginBottom: Spacing.SMALL,
                    marginTop: Spacing.SMALL
                  }}
                >
                  <Text type="danger">Error</Text>
                </div>
              )}

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  size={'large'}
                  loading={isLoading}
                >
                  Continue
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div>
              <p>
                We’ve sent you an email with a link to finish resetting your
                password.
              </p>
              <Button
                type="primary"
                onClick={() => history.push('/login')}
                block
                size={'large'}
              >
                Return to login
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </AuthBase>
  )
}

export default ForgotPasswordPage
