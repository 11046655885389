import {
  ActionOperation,
  BaseLogic,
  BlockType,
  LogicAction,
  LogicActionGroup,
  LogicOperation,
  Operation
} from 'components/survey-creator/question-blocks/base/types'
import { CombinedBlockProps } from 'data/creator'

interface AnswerObject {
  ref: string
  type: BlockType
  answer: any
}

const actionFunction = (
  block: CombinedBlockProps,
  action: LogicAction,
  newAnswerObject: AnswerObject
): boolean => {
  const answer = newAnswerObject.answer
  switch (action.condition?.operation) {
    case Operation.EQUAL:
      if (Array.isArray(answer)) {
        if (action.condition.field?.index !== undefined) {
          return (
            answer[action.condition.field?.index] ===
            action.condition.field?.value
          )
        } else {
          return answer
            .map(i => i?.label || i)
            .includes(action.condition.field?.value)
        }
      }
      return false
    case Operation.NOT_EQUAL:
      if (Array.isArray(answer)) {
        if (action.condition.field?.index !== undefined) {
          return (
            answer[action.condition.field?.index] !==
            action.condition.field?.value
          )
        }
        return !answer
          .map(i => i?.label || i)
          .includes(action.condition.field?.value)
      }
      return false
    case Operation.CONTAINS:
      return answer.toLowerCase().includes(action.condition.field?.value)
    case Operation.DOES_NOT_CONTAINS:
      return !answer.toLowerCase().includes(action.condition.field?.value)
    case Operation.LOWER_THAN:
      return answer < action.condition.field?.value
    case Operation.LOWER_EQUAL_THEN:
      return answer <= action.condition.field?.value
    case Operation.GREATER_THEN:
      return answer > action.condition.field?.value
    case Operation.GREATER_EQUAL_THAN:
      return answer >= action.condition.field?.value
    case Operation.IN_BETWEEN:
      return (
        answer > action.condition.field?.start &&
        answer < action.condition.field?.end
      )
  }
  return false
}

const actionGroupFunction = (
  block: CombinedBlockProps,
  actionGroup: LogicActionGroup,
  newAnswerObject: AnswerObject
): boolean => {
  if (actionGroup.actionOperation === ActionOperation.AND) {
    return actionGroup.actions.every(e =>
      actionFunction(block, e, newAnswerObject)
    )
  } else {
    return actionGroup.actions.some(e =>
      actionFunction(block, e, newAnswerObject)
    )
  }
}

const getNextQuestionIndex = (
  blocks: CombinedBlockProps[],
  currentQuestionIndex: number,
  totalQuestions: number,
  newAnswerObject: AnswerObject,
  logics: BaseLogic[]
): number => {
  const block = blocks[currentQuestionIndex]
  const filteredLogics = logics.filter(i => i.ref === block.blockId)
  if (filteredLogics.length === 0) return currentQuestionIndex + 1

  const getRefId = ref => {
    if (ref === 'EOS') {
      return blocks.length
    }
    return blocks.findIndex(i => i.blockId === ref)
  }

  for (const eachLogic of filteredLogics) {
    if (eachLogic.logicOperation === LogicOperation.OR) {
      if (
        eachLogic.actionGroups.some(e =>
          actionGroupFunction(block, e, newAnswerObject)
        )
      ) {
        return getRefId(eachLogic.to.ref)
      }
    } else {
      if (
        eachLogic.actionGroups.every(e =>
          actionGroupFunction(block, e, newAnswerObject)
        )
      ) {
        return getRefId(eachLogic.to.ref)
      }
    }
  }
  return currentQuestionIndex + 1
}

export default getNextQuestionIndex
