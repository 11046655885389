import { InputNumber, Space } from 'antd'
import { RatingBlockProps } from 'components/survey-creator/question-blocks/rating/types'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useSelector } from 'react-redux'
import BaseLogicComponent from '../base/logic'
import { useHandleFieldUpdate } from '../base/logic/hooks'
import { BaseLogicSubComponentProps, Operation } from '../base/types'

const RatingLogicSubcomponent = ({
  logicId,
  action,
  actionGroupId
}: BaseLogicSubComponentProps) => {
  const activeBlock = useSelector(
    CreatorSelectors.activeBlock
  ) as RatingBlockProps

  const handleFieldUpdate = useHandleFieldUpdate(logicId, actionGroupId, action)

  const handleSingleChange = value => handleFieldUpdate({ value })

  const handleStartChange = start => handleFieldUpdate({ start })

  const handleEndChange = end => handleFieldUpdate({ end })

  if (action?.condition?.operation === Operation.IN_BETWEEN) {
    return (
      <Space>
        <InputNumber
          min={0}
          max={activeBlock.numberOfStars + 1}
          style={{ width: 65 }}
          value={action.condition?.field?.start}
          onChange={handleStartChange}
        />
        and
        <InputNumber
          min={0}
          max={activeBlock.numberOfStars + 1}
          style={{ width: 65 }}
          value={action.condition?.field?.end}
          onChange={handleEndChange}
        />
      </Space>
    )
  }

  return (
    <>
      <InputNumber
        min={0}
        style={{ width: 65 }}
        max={activeBlock.numberOfStars + 1}
        value={action.condition?.field?.value}
        onChange={handleSingleChange}
      />
    </>
  )
}

const RatingLogic = () => (
  <BaseLogicComponent component={RatingLogicSubcomponent} />
)

export default RatingLogic
