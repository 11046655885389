import { AlignLeftOutlined } from '@ant-design/icons'
import {
  BaseBlockProps,
  BaseBlockWithAnswers,
  BlockDefinition,
  BlockType,
  InitialBaseBlock,
  Operation,
  QuestionColor
} from '../base/types'

export enum TextType {
  SINGLE_LINE = 'SINGLE_LINE',
  TEXT_AREA = 'TEXT_AREA'
}

export interface TextProps extends BaseBlockProps {
  textType: TextType
}

const TextInitialProps: TextProps = {
  ...InitialBaseBlock,
  type: BlockType.TEXT,
  textType: TextType.SINGLE_LINE
}

export type TextAnswerProps = TextProps & BaseBlockWithAnswers

const TextBlockDefinition: BlockDefinition = {
  title: 'Text Field',
  description: 'For short answers',
  color: QuestionColor[BlockType.TEXT],
  icon: AlignLeftOutlined,
  type: BlockType.TEXT,
  initialProps: TextInitialProps,
  operations: [
    Operation.CONTAINS,
    Operation.DOES_NOT_CONTAINS,
    Operation.BEGINS_WITH,
    Operation.END_WITH
  ]
}

export { TextBlockDefinition }
