import { DeleteFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Select, Tooltip } from 'antd'
import FlexContainer from 'components/flex-container'
import { CreatorSelectors } from 'data/creator'
import React from 'react'

import { useSelector } from 'react-redux'
import { Spacing } from 'services/styles'
import ActionGroupComponent from './logic/action-group'
import { useHandleLogicChange } from './logic/hooks'
import { ActionGroupWrapper } from './style'

interface BaseLogicComponentProp {
  component: any
}

const BaseLogicComponent = ({ component }: BaseLogicComponentProp) => {
  const logics = useSelector(CreatorSelectors.activeLogics)
  const precedingBlocksWithEOS = useSelector(
    CreatorSelectors.precedingBlocksWithEOS
  )

  const blocksLength = useSelector(CreatorSelectors.blocksLength)

  const renderBlocksText = index =>
    index === blocksLength ? 'End of survey' : `Go to question ${index + 1}`
  const { handleAddNewActionGroup, handleToChange, handleDeleteLogic } =
    useHandleLogicChange()

  return (
    <div style={{ overflowY: 'scroll', height: 'calc(100vh - 180px)' }}>
      <div style={{ marginBottom: Spacing.SMALL }}>
        <i>All rules are applied from top to bottom</i>
      </div>

      <Divider />
      {logics?.map(logic => {
        const _handleToChange = e => handleToChange(e, logic.logicId)
        return (
          <ActionGroupWrapper key={logic.logicId}>
            {logic?.actionGroups.map((actionGroup, index) => (
              <ActionGroupComponent
                key={actionGroup.actionGroupId}
                logic={logic}
                component={component}
                actionGroup={actionGroup}
                index={index}
              />
            ))}

            <FlexContainer
              justifyContent="flex-start"
              style={{ marginTop: Spacing.SMALL }}
            >
              then
              <Select
                value={logic?.to?.ref}
                onChange={_handleToChange}
                style={{
                  marginLeft: Spacing.TINY,
                  minWidth: 160,
                  width: '200px'
                }}
              >
                {precedingBlocksWithEOS.map((i, eosIndex) => (
                  <Select.Option
                    value={i.blockId}
                    key={eosIndex}
                    style={{ width: '100%' }}
                  >
                    {renderBlocksText(i.index)}
                  </Select.Option>
                ))}
              </Select>
            </FlexContainer>
            <Divider />
            <FlexContainer style={{ marginTop: -Spacing.SMALL }}>
              <Tooltip title="Add Sub-Logic">
                <Button
                  type="default"
                  icon={<PlusOutlined />}
                  size={'middle'}
                  onClick={e => handleAddNewActionGroup(logic.logicId)}
                  style={{ marginTop: Spacing.SMALL }}
                >
                  Add
                </Button>
              </Tooltip>
              <Tooltip title="Delete Logic">
                <Button
                  type="default"
                  icon={<DeleteFilled />}
                  size={'middle'}
                  onClick={e => handleDeleteLogic(logic.logicId)}
                  style={{ marginTop: Spacing.SMALL }}
                >
                  Delete
                </Button>
              </Tooltip>
            </FlexContainer>
          </ActionGroupWrapper>
        )
      })}

      <Button
        type="primary"
        icon={<PlusOutlined />}
        size={'middle'}
        onClick={e => handleAddNewActionGroup(null)}
        style={{ marginTop: Spacing.TINY }}
      >
        Add new logic
      </Button>
    </div>
  )
}

export default BaseLogicComponent
