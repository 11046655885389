import axios from 'axios'
import { QuestionAnalysisResult } from 'components/survey-creator/question-blocks/base/analysis'
import { BaseBlockProps } from 'components/survey-creator/question-blocks/base/types'
import getApiRoute from 'services/api/common/get-api-route'
import { Survey } from 'types/survey'

export interface FetchAnalysisBody {
  filters: {
    questions: {
      [questionId: string]: string[]
    }
    demographics: {
      [facet: string]: string[]
    }
  }
  comparisonProjectId?: string
}

export interface DemographicsResponse {
  facet: string
  filters: { label: string; userCount: number; userPercentage: number }[]
}

export interface QuestionsResponse extends BaseBlockProps {
  results: QuestionAnalysisResult[]
  numberOfResponses: number
}

export interface AnalysisResponse {
  demographics: DemographicsResponse[]
  questions: QuestionsResponse[]
  project: Survey
  comparisonProjects: { id: string; name: string }[]
  hasSharePermissions: boolean
}

export const fetchAnalysisResultsApi = (
  projectId: string,
  body: FetchAnalysisBody
) => {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  const comparisonProjectId = body.comparisonProjectId
  const promise = axios.post(
    getApiRoute(`/v2/analysis/${projectId}`),
    {
      ...body,
      comparisonProjectId:
        comparisonProjectId === 'none' ? null : comparisonProjectId
    },
    {
      cancelToken: source.token
    }
  )
  //  @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query')
  }
  return promise
}

export const fetchAnalysisRawResultsApi = async (projectId: string) => {
  const { data } = await axios.get(
    getApiRoute(`/v2/analysis/export/${projectId}`)
  )
  return data
}

export const fetchCleanWordCloudApi = async (props: any) => {
  const formattedData = {
    lines: props.reduce(
      (acc, curr) =>
        curr.answerCount > 0
          ? acc.concat(Array(curr.answerCount).fill(curr.label))
          : acc,
      []
    )
  }
  const { data } = await axios.post(getApiRoute(`/wordcloud`), formattedData)
  return data
}
