import { Input, Radio, Space } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox/Checkbox'
import AnswerPrompt from 'components/survey-creator/question-blocks/answer-prompt'
import React, { useState } from 'react'
import { Color } from 'services/styles'

import {
  SelectAnswerExtraProperties,
  SelectAnswerResponsePayload
} from './types'

interface StyledRadioProps extends CheckboxProps {
  isActive: boolean
}

const StyledRadio = (props: StyledRadioProps) => (
  <Radio
    {...props}
    className="p-3 mb-2 rounded-full w-full pl-4"
    style={{
      border: `1px solid ${props.isActive ? Color.CLICKABLE : Color.GREY_1}`
    }}
  >
    {props.children}
  </Radio>
)

const SingleSelectAnswer = ({
  handleSingleChange,
  choices,
  value: _value,
  other,
  noneOfTheAbove
}) => {
  const [isOtherOpen, setIsOtherOpen] = useState(false)
  const [activeValue, setActiveValue] = useState<SelectAnswerResponsePayload>()

  const onChange = e => {
    const label = e.target.value
    setIsOtherOpen(label === SelectAnswerExtraProperties.OTHER)
    setActiveValue({ label })
    handleSingleChange({ label })
  }

  const handleOtherChange = (extra: string) =>
    handleSingleChange({ label: SelectAnswerExtraProperties.OTHER, extra })

  return (
    <div className="flex flex-col justify-center flex-1 w-full">
      <AnswerPrompt text={'SELECT ONE'} />
      <Radio.Group
        onChange={onChange}
        className="w-full"
        value={activeValue?.label}
      >
        <Space direction="vertical" style={{ width: '100%' }}>
          {choices.map((choice, index) => (
            <StyledRadio
              value={choice}
              key={`${index}-${choice}`}
              isActive={activeValue?.label === choice}
            >
              {choice}
            </StyledRadio>
          ))}

          {other && (
            <StyledRadio
              value={SelectAnswerExtraProperties.OTHER}
              key={SelectAnswerExtraProperties.OTHER}
              isActive={
                activeValue?.label === SelectAnswerExtraProperties.OTHER
              }
            >
              Other
            </StyledRadio>
          )}

          {isOtherOpen && (
            <Input
              placeholder="Type your answer"
              className={'mb-3 p-3'}
              onChange={e => handleOtherChange(e.target.value)}
              style={{ borderColor: Color.GREY }}
            />
          )}
          {noneOfTheAbove && (
            <StyledRadio
              value={SelectAnswerExtraProperties.NONE_OF_THE_ABOVE}
              key={SelectAnswerExtraProperties.NONE_OF_THE_ABOVE}
              isActive={
                activeValue?.label ===
                SelectAnswerExtraProperties.NONE_OF_THE_ABOVE
              }
            >
              None of the above
            </StyledRadio>
          )}
        </Space>
      </Radio.Group>
    </div>
  )
}
export default SingleSelectAnswer
