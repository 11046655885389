import { Divider } from 'antd'
import FlexContainer from 'components/flex-container'
import TextInputClickable from 'components/survey-creator/text-input-clickable'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Color, Spacing } from 'services/styles'
import BasePropertyBlock from '../base/properties'
import { SliderBlockProps } from './types'

const SliderComponentInput = ({ value, onChange, text }) => {
  return (
    <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
      <p style={{ marginBottom: 0 }}>{text}</p>
      <TextInputClickable
        containerStyle={{
          marginLeft: Spacing.TINY,
          backgroundColor: Color.GREY,
          width: 100
        }}
        value={value}
        onChange={onChange}
        placeholder={text}
      />
    </FlexContainer>
  )
}

const SliderPropertiesComponent = () => {
  const activeBlock = useSelector(
    CreatorSelectors.activeBlock
  ) as SliderBlockProps
  const { min, max, step } = activeBlock.range
  const dispatch = useDispatch()

  const onUpdateRange = (key: 'min' | 'max' | 'step', value: any) => {
    dispatch(
      CreatorActions.putBlock({
        ...activeBlock,
        range: { ...activeBlock.range, [key]: value }
      })
    )
  }

  const onUpdateFrom = e => onUpdateRange('min', e.target.value)

  const onUpdateTo = e => onUpdateRange('max', e.target.value)

  const onUpdateStep = e => onUpdateRange('step', e.target.value)

  return (
    <>
      <Divider />
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <p style={{ marginBottom: 0 }}>Set slider properties</p>
      </FlexContainer>
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <SliderComponentInput
          value={min}
          onChange={onUpdateFrom}
          text={'From'}
        />
        <SliderComponentInput value={max} onChange={onUpdateTo} text={'To'} />
      </FlexContainer>
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <SliderComponentInput
          value={step}
          onChange={onUpdateStep}
          text={'Step'}
        />
      </FlexContainer>
    </>
  )
}

const SliderProperties = props => (
  <BasePropertyBlock>
    <SliderPropertiesComponent {...props} />
  </BasePropertyBlock>
)

export default React.memo(SliderProperties)
