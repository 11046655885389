import { Divider, Select } from 'antd'
import FlexContainer from 'components/flex-container'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BasePropertyBlock from '../base/properties'
import { RatingBlockProps } from './types'

const numberOfStars = [5, 7, 11, 13]

const RatingBlockPropertiesComponent = () => {
  const activeBlock = useSelector(
    CreatorSelectors.activeBlock
  ) as RatingBlockProps
  const dispatch = useDispatch()

  const onStarChange = newNumberOfStars => {
    dispatch(
      CreatorActions.putBlock({
        ...activeBlock,
        numberOfStars: newNumberOfStars
      })
    )
  }
  return (
    <>
      <Divider />
      <p>Number of stars</p>
      <Select
        style={{ width: '100%' }}
        value={activeBlock.numberOfStars}
        onChange={onStarChange}
      >
        {numberOfStars.map(i => (
          <Select.Option value={i} label={i} key={i}>
            <FlexContainer justifyContent={'flex-start'}>
              {i} stars
            </FlexContainer>
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

const RatingBlockProperties = props => (
  <BasePropertyBlock>
    <RatingBlockPropertiesComponent {...props} />
  </BasePropertyBlock>
)

export default React.memo(RatingBlockProperties)
