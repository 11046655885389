import axios from 'axios'
import getApiRoute from 'services/api/common/get-api-route'

export const registerApi = async (props: any) => {
  const { data } = await axios.post(getApiRoute('/user'), props)
  return data
}

export const getUserApi = async (): Promise<object> => {
  const { data } = await axios.get(getApiRoute('/user'))
  return data
}

export const sendActivateUserEmailApi = async (userId: string) => {
  const { data } = await axios.post(getApiRoute('/user/activate'), { userId })
  return data
}

export const activateUserApi = async (userId: string, code: string) => {
  const { data } = await axios.post(getApiRoute(`/user/activate/${userId}`), {
    code
  })
  return data
}

export const forgotPasswordApi = async (email: string) => {
  const { data } = await axios.post(getApiRoute(`/user/reset-password`), {
    email
  })
  return data
}

export const resetPasswordApi = async (
  userId: string,
  code: string,
  password: string
) => {
  const { data } = await axios.post(
    getApiRoute(`/user/reset-password/${userId}`),
    { code, password }
  )
  return data
}
