import { GridColumn, GridColumnProps } from '@progress/kendo-react-grid'
import BigNumber from 'bignumber.js'
import DataGridSortable from 'components/data-grid-sortable'
import BaseAnalysis from 'components/survey-creator/question-blocks/base/analysis'
import React from 'react'
import { toTableColumns } from 'services/helpers'

const RankingAnalysisComponent = props => {
  const { responses, type } = props
  const answers = responses.map(i => i.answer)
  const answerObject = answers.reduce((acc, answer) => {
    for (const result of Object.entries(answer)) {
      const [rawIdx, ans] = result as [string, string]
      const idx = parseInt(rawIdx) + 1
      //  @ts-ignore
      if (!(ans in acc)) acc[ans] = {}
      if (idx in acc[ans]) acc[ans][idx] = acc[ans][idx] + 1
      else acc[ans][idx] = 1
    }
    return acc
  }, {})

  const result = Object.entries<object>(answerObject)
    .map(([key, values]) => {
      let totalResponses = new BigNumber(0)
      let totalMultiplied = new BigNumber(0)
      for (const [rank, res] of Object.entries(values)) {
        totalResponses = totalResponses.plus(new BigNumber(res))
        totalMultiplied = totalMultiplied.plus(
          new BigNumber(res).multipliedBy(new BigNumber(rank))
        )
      }
      const average = totalMultiplied.dividedBy(totalResponses).toFixed(2)
      return { answer: key, ...values, average }
    })
    .slice()
    .sort((a, b) => parseInt(b.average) - parseInt(a.average))

  const columns: GridColumnProps[] = [
    toTableColumns({ title: 'Answer' }),
    ...Object.keys(result?.[0])
      .filter(i => i !== 'answer' && i !== 'average')
      .map(title => toTableColumns({ title })),
    toTableColumns({ title: 'Average', field: 'average' })
  ]
  return (
    <DataGridSortable data={result}>
      {columns.map((column, index) => (
        <GridColumn
          field={column.field}
          title={column.title}
          key={index}
          width={index !== 0 && 150}
        />
      ))}
    </DataGridSortable>
  )
}

const RankingAnalysis = props => (
  <BaseAnalysis {...props}>{/*<RankingAnalysisComponent />*/}</BaseAnalysis>
)

export default RankingAnalysis
