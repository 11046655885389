import { UserActions } from 'data/actions'
import { UserConstants } from 'data/constants'
import { sagaErrorFallback } from 'data/saga-fallback'
import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import { getUserApi } from 'services/api/auth'
import {
  firebaseGetCurrentUser,
  firebaseLogout
} from 'services/integrations/firebase'

function* handleAuthStateChanged() {
  const firebaseUser = yield call(firebaseGetCurrentUser)
  if (firebaseUser) {
    const rawAttributes = firebaseUser?.reloadUserInfo?.customAttributes || {}
    const customAttributes = JSON.parse(rawAttributes)
    if (customAttributes.id) {
      yield put(UserActions.setIsFetchingUser(true))
      const user: any = yield call(getUserApi)
      yield put(UserActions.setUser(user))
      yield put(UserActions.setIsFetchingUser(false))
    }
  }
}

function* handleUserLogout() {
  yield call(firebaseLogout)
  yield put(UserActions.setUser(null))
}

function* watchFirebaseAuthStateChange() {
  yield takeEvery(
    UserConstants.FIREBASE_AUTH_STATE_CHANGE,
    sagaErrorFallback,
    handleAuthStateChanged
  )
}

function* watchUserLogout() {
  yield takeEvery(UserConstants.LOGOUT, sagaErrorFallback, handleUserLogout)
}

function* userSagaRoot() {
  yield all([fork(watchFirebaseAuthStateChange), fork(watchUserLogout)])
}

export default userSagaRoot
