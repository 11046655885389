import { Divider, Switch } from 'antd'
import FlexContainer from 'components/flex-container'
import { SelectBlockProps } from 'components/survey-creator/question-blocks/sub-components/select-block-common-component/types'
import { CreatorActions } from 'data/actions'
import { CreatorSelectors } from 'data/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spacing } from 'services/styles'

const SelectPropertiesComponent = () => {
  const activeBlock = useSelector(
    CreatorSelectors.activeBlock
  ) as SelectBlockProps
  const dispatch = useDispatch()

  const onUpdateOptions = (
    option: 'randomize' | 'noneOfTheAbove' | 'other'
  ) => {
    dispatch(
      CreatorActions.putBlock({
        ...activeBlock,
        [option]: !activeBlock[option]
      })
    )
  }
  const onRandomizeChange = () => onUpdateOptions('randomize')

  const onNoneOfTheAbove = () => onUpdateOptions('noneOfTheAbove')

  const onOther = () => onUpdateOptions('other')

  return (
    <>
      <Divider />
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <p style={{ marginBottom: 0 }}>Randomize answers</p>
        <Switch
          checked={activeBlock.randomize}
          onChange={onRandomizeChange}
          size="small"
        />
      </FlexContainer>
      <Divider />
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <p style={{ marginBottom: 0 }}>Add "Other"</p>
        <Switch checked={activeBlock.other} onChange={onOther} size="small" />
      </FlexContainer>
      <Divider />
      <FlexContainer style={{ marginBottom: Spacing.SMALL }}>
        <p style={{ marginBottom: 0 }}>Add "None of the above"</p>
        <Switch
          checked={activeBlock.noneOfTheAbove}
          onChange={onNoneOfTheAbove}
          size="small"
        />
      </FlexContainer>
    </>
  )
}
export default SelectPropertiesComponent
