import styled from 'styled-components'

const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props: FlexContainerProps) =>
    props.justifyContent || 'space-between'};
  align-items: ${(props: FlexContainerProps) => props.alignItems || 'center'};
  flex-direction: ${(props: FlexContainerProps) =>
    props.flexDirection || 'row'};
`

interface FlexContainerProps {
  justifyContent?: string
  alignItems?: string
  flexDirection?: string
}

export default FlexContainer
