import { Divider } from 'antd'
import React from 'react'
import { Spacing } from 'services/styles'
import PropertyHeader from '../sub-components/property-header'

const BasePropertyBlock = ({ children }) => (
  <div style={{ padding: Spacing.TINY }}>
    <PropertyHeader />
    {children}
    <Divider />
  </div>
)

export default React.memo(BasePropertyBlock)
