import axios from 'axios'
import { firebaseGetCurrentUser } from 'services/integrations/firebase'

const noAuthRoutes = ['/user/reset-password', '/answer']

axios.interceptors.request.use(async req => {
  const isNonAuthRoute = noAuthRoutes.some(i => req.url.includes(i))
  if (isNonAuthRoute) return req
  const token = await firebaseGetCurrentUser()?.getIdToken()
  req.headers = {
    ...req.headers,
    Authorization: `Bearer ${token}`
  }
  return req
})
