import { BlocksDefinition } from 'components/survey-creator/question-blocks'
import { AppReducerState } from 'data/reducers'
import { createSelector } from 'reselect'

export const isPutSurveyLoading = (state: AppReducerState) =>
  state.creator.creator.isLoading

export const creatorSurveyState = (state: AppReducerState) =>
  state.creator.creator.state

// blocks
export const baseBlocks = (state: AppReducerState) => state.creator.blocks

export const blocks = createSelector(baseBlocks, blocks => blocks.blocks)

export const blocksLength = createSelector(
  baseBlocks,
  blocks => blocks.blocks.length
)

export const activeBlockId = createSelector(
  baseBlocks,
  blocks => blocks.activeBlockId
)

export const activeBlock = createSelector(
  blocks,
  activeBlockId,
  (blocks, activeBlockId) => blocks.find(i => i.blockId === activeBlockId)
)

export const activeBlockDefinition = createSelector(
  activeBlock,
  props => BlocksDefinition?.[props?.type]
)

export const activeBlockIndex = createSelector(
  blocks,
  activeBlockId,
  (blocks, activeBlockId) => blocks.findIndex(i => i.blockId === activeBlockId)
)
export const precedingBlocks = createSelector(
  blocks,
  activeBlockIndex,
  blocksLength,
  (blocks, activeBlockIndex, blocksLength) => {
    if (activeBlockIndex >= blocksLength - 1) {
      return []
    } else {
      return blocks
        .map((i, index) => ({ ...i, index }))
        .slice(activeBlockIndex + 1, blocksLength)
    }
  }
)

export const endOfSurveyId = 'EOS'

export const precedingBlocksWithEOS = createSelector(
  precedingBlocks,
  blocksLength,
  (blocks, blocksLength): { index: number; blockId: string }[] => {
    //  @ts-ignore
    return [...blocks, { blockId: endOfSurveyId, index: blocksLength }]
  }
)

export const isBlocksReady = createSelector(baseBlocks, block => block.isReady)

// logics
export const baseLogic = (state: AppReducerState) => state.creator.logics

export const logics = createSelector(baseLogic, logics => logics.logics)

export const activeLogics = createSelector(
  logics,
  activeBlockId,
  (logic, activeBlockId) => logic.filter(i => i.ref === activeBlockId)
)

export const isLogicsReady = createSelector(baseLogic, logic => logic.isReady)

const getId = (_, id) => id

export const getBlockById = createSelector(blocks, getId, (blocks, id) =>
  blocks.find(i => i.blockId === id)
)

export const isBlockActive = createSelector(
  activeBlockId,
  getId,
  (blockId, id) => blockId === id
)
