import { createAction } from 'redux-actions'
import UserConstants from './constants'

export const setUser = user => createAction(UserConstants.SET_USER)({ user })

export const setIsFetchingUser = (isFetchingUser: boolean) =>
  createAction(UserConstants.IS_FETCHING_USER)({ isFetchingUser })

export const setFirebaseIsReady = () =>
  createAction(UserConstants.FIREBASE_SET_READY)()

export const firebaseAuthStateChange = () =>
  createAction(UserConstants.FIREBASE_AUTH_STATE_CHANGE)()

export const userLogout = () => createAction(UserConstants.LOGOUT)()
