import { EllipsisOutlined } from '@ant-design/icons'
import { Button, Dropdown, Menu, Space, Tooltip, Typography } from 'antd'
import FlexContainer from 'components/flex-container'
import { BlocksDefinition } from 'components/survey-creator/question-blocks'
import TextInputClickable from 'components/survey-creator/text-input-clickable'
import TypeIndexIndicator from 'components/survey-creator/type-index-indicator'
import { CreatorActions, CreatorSelectors } from 'data/creator'
import { CreatorMenuTabEnums } from 'pages/creator/editor/types'
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useBlockManipulation, useParamSelector } from 'services/hooks'
import { Color, Spacing } from 'services/styles'
import AddNewMenu from '../../editor/add-new-menu'
import { NumberRow, TitleRow, Wrapper } from './style'
import BaseTags from './tags'
import { BaseBlockWithComponent } from './types'

const { Text } = Typography

const FooterBlock = ({ isBlockActive, index }) =>
  isBlockActive && (
    <Space>
      <Tooltip title={'Feature coming soon'} placement="bottom">
        <Button type="default" disabled>
          Add from library
        </Button>
      </Tooltip>
      <AddNewMenu index={index} />
    </Space>
  )

const BaseBlock = (
  props: BaseBlockWithComponent & {
    activeBlockId?: string
    handleChangeTab: (tab) => void
  }
) => {
  const {
    title,
    blockId,
    index,
    children,
    tagsList = () => [],
    activeBlockId,
    handleChangeTab
  } = props
  const [isTitleActivated, setIsTitleActivated] = useState(false)
  const { onDuplicateClick, onDeleteClick } = useBlockManipulation()

  const isBlockActive = useParamSelector(
    CreatorSelectors.isBlockActive,
    blockId
  )

  const dispatch = useDispatch()

  const onTextChange = useCallback(
    e => {
      setIsTitleActivated(true)
      dispatch(CreatorActions.putBlock({ blockId, title: e.target.value }))
    },
    // eslint-disable-next-line
    [blockId, activeBlockId]
  )

  const onSetActiveBlock = useCallback(
    () => dispatch(CreatorActions.setActiveBlock(blockId)),
    // eslint-disable-next-line
    [blockId, activeBlockId]
  )

  const blockDefinition = BlocksDefinition[props.type]
  const { icon, color } = blockDefinition.blockDefinition

  const tags: any[] = [...BaseTags(props), ...tagsList(props)]

  const handleOnBlockClick = tab => {
    return () => handleChangeTab(tab)
  }

  const menu = (
    <Menu onClick={e => e.domEvent.stopPropagation()}>
      <Menu.Item onClick={onDuplicateClick}>Duplicate</Menu.Item>
      <Menu.Item
        onClick={handleOnBlockClick(CreatorMenuTabEnums.EDIT_QUESTION)}
      >
        Properties
      </Menu.Item>
      <Menu.Item onClick={handleOnBlockClick(CreatorMenuTabEnums.LOGIC)}>
        Logic
      </Menu.Item>
      <Menu.Item onClick={handleOnBlockClick(CreatorMenuTabEnums.PREVIEW)}>
        Preview
      </Menu.Item>
      <Menu.Item onClick={onDeleteClick} style={{ color: Color.DANGER }}>
        Delete
      </Menu.Item>
    </Menu>
  )

  return (
    //  @ts-ignore
    <Wrapper
      onClick={onSetActiveBlock}
      active={isBlockActive}
      className={'question-block'}
    >
      <NumberRow>
        <TypeIndexIndicator index={index} color={color} icon={icon} />
        <Dropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomLeft"
          overlayStyle={{ width: 150 }}
        >
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      </NumberRow>
      <TitleRow>
        <TextInputClickable
          containerStyle={{ height: 35 }}
          inputStyle={{ fontSize: 16, fontWeight: 600 }}
          value={title}
          onChange={onTextChange}
          placeholder="Type your question here"
        />
      </TitleRow>

      {isTitleActivated && !title && (
        <Text type="danger">Please enter a question prompt to continue</Text>
      )}

      {children}

      <FlexContainer style={{ marginTop: Spacing.SMALL }}>
        {tags.length ? (
          <FlexContainer>
            {tags.map((i, index) => (
              <div key={index}>{i}</div>
            ))}
          </FlexContainer>
        ) : (
          <div />
        )}
        <FooterBlock isBlockActive={isBlockActive} index={index} />
      </FlexContainer>
    </Wrapper>
  )
}

export default React.memo(BaseBlock)
