import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts'
import { GridColumn } from '@progress/kendo-react-grid'
import { Divider } from 'antd'
import AnalysisCardFooter from 'components/analysis-card-footer'
import DataGridSortable from 'components/data-grid-sortable'
import { piechartLabelContent } from 'components/survey-creator/question-blocks/analysis-custom-components'
import BaseAnalysis from 'components/survey-creator/question-blocks/base/analysis'
import { ChartType } from 'components/survey-creator/question-blocks/base/types'
import React, { useState } from 'react'
import { toTableColumns } from 'services/helpers'

const columns = [
  toTableColumns({ title: 'Answer', field: 'label' }),
  toTableColumns({ title: 'Answers (%)', field: 'answerPercentage' }),
  toTableColumns({ title: 'Count', field: 'answerCount' })
]

const RatingAnalysisComponent = ({ response }) => {
  const { results, numberOfResponses, type } = response

  const [mode, setMode] = useState(ChartType.NONE)

  const renderComponent = () => {
    switch (mode) {
      case ChartType.COLUMN:
        return (
          <DataGridSortable data={results}>
            {columns.map((column, index) => (
              <GridColumn
                field={column.field}
                title={column.title}
                key={index}
                width={index !== 0 && 150}
              />
            ))}
          </DataGridSortable>
        )
      case ChartType.GRID:
        return (
          <Chart>
            <ChartSeries>
              <ChartSeriesItem
                type="column"
                data={results.map(i => i.userPercentage)}
                labels={{
                  format: '{0:n1}%',
                  visible: true,
                  position: 'outsideEnd',
                  background: 'transparent'
                }}
              />
            </ChartSeries>
            <ChartTooltip format="{0:n1}%" />
            <ChartCategoryAxis>
              <ChartCategoryAxisItem
                categories={results.map(i => i.label)}
                labels={{ rotation: 'auto' }}
              />
            </ChartCategoryAxis>
            <ChartValueAxis>
              <ChartValueAxisItem title={{ text: 'Answers' }} />
            </ChartValueAxis>
          </Chart>
        )
      case ChartType.PIE:
        return (
          <Chart>
            <ChartLegend position="bottom" />
            <ChartTooltip format="{0:n1}%" />
            <ChartSeries>
              <ChartSeriesItem
                type="pie"
                data={results}
                field="userPercentage"
                categoryField="label"
                labels={{ visible: true, content: piechartLabelContent }}
              />
            </ChartSeries>
          </Chart>
        )
    }
  }

  return (
    <>
      {renderComponent()}
      <Divider />
      <AnalysisCardFooter
        options={[ChartType.COLUMN, ChartType.GRID, ChartType.PIE]}
        currentMode={mode}
        onClick={setMode}
        questionType={type}
        completed={numberOfResponses}
      />
    </>
  )
}

const RatingAnalysis = props => (
  <BaseAnalysis {...props}>
    {/*<RatingAnalysisComponent {...props} />*/}
  </BaseAnalysis>
)

export default RatingAnalysis
