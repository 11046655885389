import BaseAnalysis from 'components/survey-creator/question-blocks/base/analysis'
import React from 'react'
import SelectAnalysisCommonComponent from '../sub-components/select-analysis-common-component'

const SingleSelectAnalysis = props => (
  <BaseAnalysis {...props}>
    <SelectAnalysisCommonComponent isSingle {...props} />
  </BaseAnalysis>
)

export default SingleSelectAnalysis
